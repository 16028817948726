import React from 'react'
import { useSelector } from 'react-redux'

const SecondComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const containerArrayDetails = activeLanguage === "Español" ? [
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "Obtenga una cuota gratis",
            paragraphs: "Una vez que haya identificado su tractor ideal, solicite un presupuesto online gratuito. Simplemente especifique el plazo de alquiler deseado y los archivos adjuntos que pueda necesitar."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Asegure su reserva",
            paragraphs: "Finalice su reserva en línea o contactando a nuestro amigable equipo de atención al cliente. Confirmaremos la disponibilidad y le guiaremos a través del sencillo contrato de alquiler."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "Recoger y comenzar",
            paragraphs: "Dirígete a nuestro lugar de recogida designado (u organiza la entrega, si está disponible) con una licencia de conducir válida y toda la documentación requerida. Le proporcionaremos un recorrido rápido por el funcionamiento y las características de seguridad del tractor."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Complete su proyecto y regrese",
            paragraphs: "Afronte su proyecto con confianza, sabiendo que tiene el equipo adecuado a su disposición. Cuando termine, simplemente devuelva el tractor al lugar designado."
        },
    ]
    :
    [
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "Get a Free Quote",
            paragraphs: "Once you've identified your ideal tractor, request a free online quote. Simply specify your desired rental timeframe and any attachments you might need."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Secure Your Booking",
            paragraphs: "Finalize your booking online or by contacting our friendly customer support team. We'll confirm availability and guide you through the simple rental agreement."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/route.mp4",
            mainHeading: "Pick Up & Get Started",
            paragraphs: "Head to our designated pick-up location (or arrange for delivery, if available) with a valid driver's license and any required documentation. We'll provide a quick walk-through of the tractor's operation and safety features."
        },
        {
            videoLink: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/destination.mp4",
            mainHeading: "Complete Your Project & Return",
            paragraphs: "Tackle your project with confidence, knowing you have the right equipment at your disposal. When finished, simply return the tractor to the designated location."
        },
    ]

    return (
        <div className='SecondComponent'>

            <p className='mainHeading'>
            {
          activeLanguage === "Español" ?
            "Nuestro proceso de reserva simplificado"
            :
            "Our Streamlined Booking Process"
        }
            </p>

            <div className='container'>

                {
                    containerArrayDetails.map((details, index) => {
                        return (
                            <div
                                className='box'
                                key={index}>
                                <div className="boxTop">
                                    <video
                                        src={details.videoLink}
                                        autoPlay
                                        loop
                                        controls={false}
                                        width="120px" // Adjust width as needed
                                        height="auto" // Adjust height as needed
                                    />
                                    <div className='boxTopRight'>
                                        <p>
                                            {details.mainHeading}
                                        </p>
                                    </div>
                                </div>
                                <div className="boxBottom">
                                    <p>
                                        {details.paragraphs}
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default SecondComponent
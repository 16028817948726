import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';

const ThirdComponent = () => {

    const [expandedState, setExpandedState] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentArray = activeLanguage === "Español" ? [
        {
            tag: "Encontrar el tractor adecuado",
            details: "Nuestros agentes de soporte conocen los diferentes modelos de tractores y accesorios disponibles. Pueden ayudarlo a elegir el equipo adecuado según sus necesidades específicas."
        },
        {
            tag: "Proceso de reserva y alquiler",
            details: "¿Necesita ayuda para navegar por el proceso de reserva en línea? Nuestros agentes estarán encantados de guiarle paso a paso, responder a cualquier pregunta que pueda tener y ayudarle a reservar el alquiler de su tractor."
        },
        {
            tag: "Apoyo técnico.",
            details: "¿Tiene alguna dificultad técnica en la plataforma? Nuestros agentes están disponibles para solucionar problemas y garantizar una experiencia de usuario perfecta."
        },
        {
            tag: "Preguntas frecuentes y consultas generales",
            details: "¿Tiene alguna pregunta sobre tarifas de alquiler, pólizas de seguro u otros aspectos de nuestro servicio? ¡No dudes en comunicarte! Nuestros agentes están aquí para brindarle respuestas y abordar sus inquietudes."
        },

    ] : [
        {
            tag: "Finding the right tractor",
            details: "Our support agents know the different tractor models and accessories available. They can help you choose the right equipment based on your specific needs."
        },
        {
            tag: "Reservation and rental process",
            details: "Need help navigating the online booking process? Our agents will be happy to guide you step by step, answer any questions you may have and help you book your tractor rental."
        },
        {
            tag: "Technical support.",
            details: "Do you have any technical difficulties on the platform? Our agents are available to troubleshoot issues and ensure a seamless user experience."
        },
        {
            tag: "Frequently asked questions and general queries",
            details: "Do you have any questions about rental rates, insurance policies or other aspects of our service? Don't hesitate to reach out! Our agents are here to provide you with answers and address your concerns."
        },

    ]

    return (
        <div className="ThirdComponent">

            <div className='left'>

                <img
                    src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/field_agent.webp"
                    alt=""
                    className='w-full 1000px:w-[90%] rounded-[6px]' />

            </div>

            <div className="rigth">

                <div className="heading">
                    {
                        activeLanguage === "Español" ?
                            "¡Cómo nuestros dedicados agentes pueden ayudar a los agricultores a alquilar tractores!"
                            :
                            "How our dedicated agents can help farmers rent tractors!"
                    }
                </div>

                {
                    componentArray.map((details, index) => {
                        return (
                            <div key={index}>

                                <div className='box-top' onClick={() => { setExpandedState(index) }}>
                                    <span>{details.tag}</span>
                                    <ExpandMoreIcon
                                        style={{
                                            transform: expandedState === index ? 'rotate(180deg)' : 'rotate(0deg)'
                                        }} />
                                </div>

                                <div className='box-bottom'
                                    style={{
                                        height: expandedState === index ? '100px' : '0px',
                                        display: expandedState === index ? 'block' : 'none'
                                    }}>
                                    {details.details}
                                </div>

                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default ThirdComponent
import React from 'react'
import serviceBackgroundVideo from '../../../../../assets/video/service.mp4'
import { useSelector } from 'react-redux'

const Banner = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className="Banner">

            <video autoPlay loop muted>
                <source src={serviceBackgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <h1>
                {
                    activeLanguage === "Español" ?
                        "Tractores comerciales diseñados para superar cualquier desafío"
                        :
                        "Commercial Tractors Built to Conquer Any Challenge"
                }
            </h1>

            {
                activeLanguage === "Español" ?
                    <p className='paragraphOne'>
                        En Holatractor entendemos las demandas de las operaciones comerciales y agrícolas a gran escala. Es por eso que ofrecemos una sólida selección de tractores comerciales diseñados para afrontar los trabajos más difíciles con potencia, eficiencia y confiabilidad inquebrantables.
                    </p>
                    :
                    <p className='paragraphOne'>
                        At Holatractor, we understand the demands of large-scale agriculture and commercial operations. That's why we offer a robust selection of commercial tractors designed to tackle the toughest jobs with unwavering power, efficiency, and reliability.
                    </p>
            }

        </div>
    )
}

export default Banner
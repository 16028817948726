import React from 'react'
import { useSelector } from 'react-redux'

const SeventhComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentArray = activeLanguage === "Español" ? [
        {
            heading: "Solución rentable",
            description: "Evite el gasto inicial de comprar un tractor y los costos continuos de mantenimiento y almacenamiento. Alquile solo el equipo que necesita para la duración específica del proyecto.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/market_disturption.png"
        },
        {
            heading: "Flexibilidad y comodidad",
            description: "Elija entre una diversa selección de tractores que se adapten a los requisitos de su proyecto. Alquile por unas horas, un día, una semana o más: ¡la elección es suya!",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/cutomers_expect.png"
        },
        {
            heading: "Sin problemas de mantenimiento",
            description: "Nos encargamos de todo el mantenimiento y las reparaciones, garantizando que su tractor alquilado esté en las mejores condiciones y listo para afrontar su proyecto.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/operational.png"
        },
        {
            heading: "Perfecto para uso ocasional",
            description: "Si sólo necesitas un tractor para tareas estacionales o proyectos específicos, el alquiler es una opción inteligente y práctica.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/data_decission.png"
        },
    ]
        :
        [
            {
                heading: "Cost-Effective Solution",
                description: "Avoid the upfront expense of purchasing a tractor and the ongoing costs of maintenance and storage. Rent only the equipment you need for the specific project duration.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/market_disturption.png"
            },
            {
                heading: "Flexibility & Convenience",
                description: "Choose from a diverse selection of tractors to match your project requirements. Rent for a few hours, a day, a week, or longer – the choice is yours!",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/cutomers_expect.png"
            },
            {
                heading: "No Maintenance Hassles",
                description: "We take care of all maintenance and repairs, ensuring your rented tractor is in top condition and ready to tackle your project.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/operational.png"
            },
            {
                heading: "Perfect for Occasional Use",
                description: "If you only need a tractor for seasonal tasks or specific projects, renting is a smart and practical choice.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/data_decission.png"
            },
        ]

    return (
        <div
            className='SeventhComponent'>

            <div
                className='seventh_container'
                style={{
                    // background: 'radial-gradient(50% 50% at 50% 50%, rgba(158, 110, 230, 0.28) 0%, rgba(158, 110, 230, 0) 100%)'
                }}>

                <h1
                    className='text-3xl 1000px:text-5xl'>
                    {
                        activeLanguage === "Español" ?
                            "¿Por qué elegir el alquiler de tractores?"
                            :
                            "Why Choose Tractor Rentals?"
                    }
                </h1>

                <div
                    className='grid-cols-1 800px:grid-cols-2 data_container'>

                    {
                        componentArray.map((details, index) => {
                            return (
                                <div
                                    key={index}
                                    className='data_box'>

                                    <img
                                        alt={details.heading}
                                        src={details.imageLink}
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            objectFit: 'cover'
                                        }} />

                                    <div
                                        className='text-lg 1000px:text-2xl data_text_container'>

                                        <p>
                                            <b>
                                                {details.heading}
                                            </b>
                                        </p>

                                        <p>
                                            {details.description}
                                        </p>

                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

            </div>

        </div>
    )
}

export default SeventhComponent
import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const SecondComponent = () => {

    const [activeContentHeading, setActiveContnentHeading] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const contentArray = activeLanguage === "Español" ? [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Crear un perfil en hola tractor",
            para: "Crear un perfil con Hola Tractor es rápido y sencillo. Simplemente visite nuestro sitio web o descargue la aplicación, luego siga las instrucciones para registrarse. Proporcione información básica como su nombre, dirección de correo electrónico y ubicación."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Únete a tu comunidad",
            para: "Una vez registrado, puede conectarse fácilmente con grupos comunitarios cercanos, propietarios o inquilinos, que ofrecen o buscan servicios asequibles."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/listings.png",
            heading: "Crear un listado detallado",
            para: "Planifique detalles esenciales como el tipo de tractor que necesita, la duración del alquiler, las tareas específicas para las que se utilizará, su ubicación y su presupuesto. La plataforma de Hola Tractor lo conectará con propietarios de tractores cercanos que cumplan con sus criterios, permitiéndole seleccionar la opción más adecuada."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Seleccione un tipo de tractor",
            para: "Explore los listados disponibles y seleccione el tipo de tractor que satisfaga sus necesidades."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/attachment.png",
            heading: "Seleccione si hay algún archivo adjunto",
            para: "Hola Tractor ofrece una amplia gama de maquinaria de acoplamiento para tractores, brindando a los agricultores soluciones versátiles para satisfacer sus necesidades agrícolas. Con solo unos pocos clics, los usuarios pueden explorar nuestro inventario de accesorios, incluidos arados, cultivadores, sembradoras y más. Simplemente seleccione el equipo deseado."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/duration.png",
            heading: "Especificar duración del alquiler",
            para: "Simplemente especifique la duración de alquiler deseada al enviar sus requisitos. Nuestra plataforma lo conecta con propietarios de tractores cercanos que pueden adaptarse a su calendario."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/tasks.png",
            heading: "Describir las tareas para las que se utilizará",
            para: "Hola Tractor se utilizará para una variedad de tareas que incluyen arar, labrar, rastrillar, plantar y cultivar campos y muchas más. Su versatilidad le permite manejar diversas actividades agrícolas de manera eficiente y efectiva, contribuyendo a una mayor productividad y rendimiento para los agricultores."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/budget.png",
            heading: "Especifica tu presupuesto",
            para: "Especifique su presupuesto junto con sus requisitos para el tractor. Hola Tractor ofrece una gama de opciones asequibles adaptadas a sus necesidades. Es un proceso rápido y eficiente que le garantiza obtener el tractor que necesita a un precio asequible."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/location.png",
            heading: "Especifica tu ubicación",
            para: "Para alquiler de tractores con Hola Tractor, simplemente especifique su ubicación y requisitos en nuestra plataforma. Lo conectaremos con propietarios de tractores cercanos que ofrecerán tarifas asequibles."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/preview_like.png",
            heading: "Obtenga una vista previa de sus requisitos y publíquelos",
            para: "Obtenga una vista previa de sus requisitos y publíquelos en nuestra plataforma. Incluya detalles como el tipo de tractor que necesita, la duración del alquiler, las tareas para las que se utilizará y su presupuesto. Nuestro avanzado sistema de búsqueda lo conectará con propietarios de tractores cercanos que ofrecerán tarifas asequibles."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/buisness.png",
            heading: "Obtenga conexiones de tractor para su oferta",
            para: "Conéctese con propietarios de tractores rápida y fácilmente a través de Hola Tractor para todas sus necesidades. Con Hola Tractor, alquilar un tractor es un proceso perfecto."
        },
    ]
    :
    [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Create a profile on Hello Tractor",
            para: "Creating a profile with Hola Tractor is quick and easy. Simply visit our website or download the app, then follow the instructions to register. Provide basic information such as your name, email address, and location."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Join your community",
            para: "Once registered, you can easily connect with nearby community groups, homeowners or renters, offering or seeking affordable services."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/listings.png",
            heading: "Create a detailed listing",
            para: "Plan essential details such as the type of tractor you need, the length of the rental, the specific tasks it will be used for, your location, and your budget. Hola Tractor's platform will connect you with nearby tractor owners who meet your criteria, allowing you to select the most suitable option."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Select a type of tractor",
            para: "Browse available listings and select the type of tractor that meets your needs."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/attachment.png",
            heading: "Select if there are any files attached",
            para: "Hola Tractor offers a wide range of tractor hitch machinery, providing farmers with versatile solutions to meet their agricultural needs. With just a few clicks, users can browse our inventory of accessories, including plows, cultivators, planters and more. Simply select the desired equipment."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/duration.png",
            heading: "Specify rental duration",
            para: "Simply specify your desired rental duration when submitting your requirements. Our platform connects you with nearby tractor owners who can fit your schedule."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/tasks.png",
            heading: "Describe the tasks for which it will be used",
            para: "Hello Tractor will be used for a variety of tasks including plowing, tilling, raking, planting and cultivating fields and many more. Its versatility allows it to handle various agricultural activities efficiently and effectively, contributing to greater productivity and yield for farmers."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/budget.png",
            heading: "Specify your budget",
            para: "Specify your budget along with your requirements for the tractor. Hello Tractor offers a range of affordable options tailored to your needs. It's a quick and efficient process that ensures you get the tractor you need at an affordable price."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/location.png",
            heading: "Specify your location",
            para: "For tractor rental with Hola Tractor, simply specify your location and requirements on our platform. We'll connect you with nearby tractor owners who will offer affordable rates."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/preview_like.png",
            heading: "Preview your requirements and publish them",
            para: "Preview your requirements and publish them on our platform. Include details such as the type of tractor you need, the length of the rental, the tasks it will be used for, and your budget. Our advanced search system will connect you with nearby tractor owners who will offer affordable rates."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/buisness.png",
            heading: "Get tractor connections for your offer",
            para: "Connect with tractor owners quickly and easily through Hola Tractor for all your needs. With Hola Tractor, renting a tractor is a seamless process."
        },
    ]

    return (
        <div className='SecondComponent'>

            <div className="headings">

                <h2>
                    {
                        activeLanguage === "Español" ?
                            "Optimice sus necesidades"
                            :
                            "Optimize your needs"
                    }
                </h2>

                <p>
                    {
                        activeLanguage === "Español" ?
                            "Ofrecemos un proceso simplificado para publicar sus requisitos y conectarse con propietarios de tractores cercanos a costos asequibles. Simplemente proporcione detalles sobre sus necesidades, incluido el tipo de tractor requerido, la duración del alquiler, las tareas para las que se utilizará y su presupuesto. Nuestra plataforma filtra listados según la ubicación, lo que garantiza que encuentre tractores cercanos. Puede comparar fácilmente opciones y seleccionar la más adecuada."
                            :
                            "We offer a simplified process to post your requirements and connect with nearby tractor owners at affordable costs. Simply provide details about your needs, including the type of tractor required, the length of the rental, the tasks it will be used for, and your budget. Our platform filters listings based on location, ensuring you find nearby tractors. You can easily compare options and select the most suitable one."
                    }
                </p>

            </div>

            <div className="Container">

                <div className="left-container">

                    <div className="list-container">
                        {
                            contentArray.map((details, index) => {
                                return (
                                    <div
                                        className='box'
                                        key={index}
                                        onClick={() => { setActiveContnentHeading(index) }}>
                                        <div
                                            className='heading'
                                            style={{
                                                color: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                            }}>
                                            <p>{index + 1 > 9 ? index + 1 : `0${index + 1}`}.</p>
                                            <p>{details.heading}</p>
                                        </div>
                                        <div className='box-bottom' style={{
                                            backgroundColor: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                        }} />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                <div className="right-container">

                    <div className='contents'>

                        <img
                            src={contentArray[activeContentHeading].img}
                            alt="" />

                        <div className="Heading">
                            {contentArray[activeContentHeading].heading}
                        </div>

                        <div className="Paragraph">
                            {contentArray[activeContentHeading].para}
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default SecondComponent
import React from 'react'
import './CommunityBooking.scss'
import Banner from './Banner'
import FirstComponent from './FirstComponent'
import SecondComponent from './SecondComponent'
import ThirdComponent from './ThirdComponent'

const CommunityBooking = () => {
  return (
    <div className='CommunityBooking'>

        <Banner />

        <FirstComponent />

        <SecondComponent />

        <ThirdComponent />

    </div>
  )
}

export default CommunityBooking
import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useState } from 'react';

const CommunityComponentFour = () => {

    const [ActiveContentHeading, setActiveContentHeading] = useState(0)

    const contentArray = [
        {
            heading: "Heading1",
            contents: [
                "content1",
                "content1",
                "content1",
                "content1",
            ]
        },
        {
            heading: "Heading2",
            contents: [
                "content2",
                "content2",
                "content2",
                "content2",
            ]
        },
        {
            heading: "Heading3",
            contents: [
                "content3",
                "content3",
                "content3",
                "content3",
            ]
        },
    ]

    return (
        <div className='w-full py-[40px] bg-gray-100'>

            <div className='w-[90%] 1000px:w-[80%] mx-auto flex items-center justify-center flex-col 700px:flex-row'>

                <div className='w-full 700px:w-[25%] flex flex-row flex-wrap 700px:flex-nowrap 700px:flex-col justify-evenly gap-[30px]'>

                    {
                        contentArray.map((contentHeading, index)=>{
                            return (
                                <div
                                key={index}
                                className='flex items-center gap-[8px] cursor-pointer'
                                onClick={()=>{ setActiveContentHeading(index) }}>

                                    <p 
                                    className={`text-[20px] font-bold ${ActiveContentHeading === index ? 'text-black' : 'text-gray-400'}`}>
                                        {contentHeading.heading}
                                    </p>

                                    <ArrowForwardIcon className={`${ActiveContentHeading === index ? 'text-black' : 'text-gray-400'}`} />

                                </div>
                            )
                        })
                    }

                </div>

                <div className='w-full 700px:w-[75%] p-[30px] grid grid-cols-1 1000px:grid-cols-2 gap-8'>

                    {
                        contentArray[ActiveContentHeading].contents.map((contents)=>{
                            return (
                                <p 
                                className='w-full bg-white text-center p-[20px] font-bold text-[22px] rounded-[10px] shadow-lg cursor-pointer'>
                                    {contents}
                                </p>
                            )
                        })
                    }

                </div>

            </div>

            <img 
            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/palm_collector.jpg" 
            alt=""
            className='w-full object-cover py-[20px]' />

        </div>
    )
}

export default CommunityComponentFour
import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const FirstComponent = () => {
  
    const [activeContentHeading, setActiveContnentHeading] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const contentArray = activeLanguage === "Español" ? [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Acceso asequible",
            para: "Evite el alto costo de comprar y mantener su propio tractor. Alquile el equipo que necesita para proyectos específicos, grandes o pequeños."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Variedad de opciones",
            para: "Elija entre una amplia gama de tractores y accesorios perfectos para proyectos de paisajismo, tareas de jardinería, mantenimiento de propiedades o incluso iniciativas impulsadas por la comunidad."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Precios simples y transparentes",
            para: "Nuestra estructura de precios transparente elimina tarifas ocultas y garantiza que usted pague solo por el tiempo que utiliza el equipo."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Reserva conveniente",
            para: "Explore fácilmente los tractores disponibles, reserve horarios de alquiler y administre sus reservas a través de nuestra plataforma en línea fácil de usar o nuestra aplicación móvil."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Soporte y orientación",
            para: "Nuestro equipo está aquí para responder sus preguntas y ofrecerle orientación sobre cómo elegir el equipo adecuado y operar el tractor de manera segura."
        },
    ]
        :
        [
            {
                img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
                heading: "Affordable Access",
                para: "Skip the high cost of purchasing and maintaining your own tractor. Rent the equipment you need for specific projects, big or small."
            },
            {
                img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
                heading: "Variety of Options",
                para: "Choose from a wide range of tractors and attachments perfect for landscaping projects, gardening tasks, property maintenance, or even community-driven initiatives."
            },
            {
                img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
                heading: "Simple & Transparent Pricing",
                para: "Our transparent pricing structure eliminates hidden fees and ensures you only pay for the time you use the equipment."
            },
            {
                img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
                heading: "Convenient Booking",
                para: "Easily browse available tractors, reserve rental times, and manage your bookings through our user-friendly online platform or mobile app."
            },
            {
                img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
                heading: "Support & Guidance",
                para: "Our team is here to answer your questions and offer guidance on choosing the right equipment and operating the tractor safely."
            },
        ]

    return (
        <div className='FirstComponent'>

            <div className="headings">

                <h2>
                    {
                        activeLanguage === "Español" ?
                            "Cómo funciona"
                            :
                            "How it Works"
                    }
                </h2>

                <p>
                    {
                        activeLanguage === "Español" ?
                            "Nuestra plataforma conecta a los residentes con acceso a una amplia gama de tractores y accesorios dentro de su comunidad local. Así es como beneficia a todos."
                            :
                            "Our platform connects residents with access to a diverse range of tractors and attachments within their local community. Here's how it benefits everyone."
                    }
                </p>

            </div>

            <div className="Container">

                <div className="left-container">

                    <div className="list-container">
                        {
                            contentArray.map((details, index) => {
                                return (
                                    <div
                                        className='box'
                                        key={index}
                                        onClick={() => { setActiveContnentHeading(index) }}>
                                        <div
                                            className='heading'
                                            style={{
                                                color: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                            }}>
                                            <p>{index + 1 > 9 ? index + 1 : `0${index + 1}`}.</p>
                                            <p>{details.heading}</p>
                                        </div>
                                        <div className='box-bottom' style={{
                                            backgroundColor: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                        }} />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                <div className="right-container">

                    <div className='contents'>

                        <img
                            src={contentArray[activeContentHeading].img}
                            alt="" />

                        <div className="Heading">
                            {contentArray[activeContentHeading].heading}
                        </div>

                        <div className="Paragraph">
                            {contentArray[activeContentHeading].para}
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default FirstComponent
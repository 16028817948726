import React from 'react'
import Dash from '../../../assets/demoImages/minus.png'
import { useSelector } from 'react-redux'

const SeconsComponent = () => {
  const { activeLanguage } = useSelector(state => state.Languages)
  return (
    <div className='SeconsComponent'>

      {
        activeLanguage === "Español" ?
          <div className="left">

            <div className="heading">
              Beneficios para propietarios de tractores
            </div>

            <div className="para1">
              Incluir sus servicios de tractores en plataformas en línea holatractor o mercados agrícolas puede ofrecer varios beneficios a los propietarios de tractores.
            </div>

            <div className="keypoints">

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Mayor visibilidad</span>
              </div>

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Conveniencia para los clientes</span>
              </div>

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Contratar operador holatractor</span>
              </div>

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Realice un seguimiento de su tractor con nuestro dispositivo integrado IOT</span>
              </div>

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Mayores reservas</span>
              </div>

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Credibilidad y confianza</span>
              </div>


              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Marketing y publicidad</span>
              </div>

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Comodidad de pago</span>
              </div>

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Atención al agente del cliente</span>
              </div>

            </div>

            <div className="para1">
              Todas las funciones a las que puede acceder en su propio panel web y aplicación móvil para monitorear los servicios.Con los servicios de holatractor no solo puede proporcionar los servicios del tractor sino que también puede apoyar a la comunidad agrícola para aumentar su productividad.
            </div>

            <div className="para1">

            </div>

          </div>
          :
          <div className="left">

            <div className="heading">
              Benefits for tractor owners
            </div>

            <div className="para1">
              Listing your tractor services on holatractor online platforms or agricultural markets can offer several benefits to tractor owners.
            </div>

            <div className="keypoints">

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Greater visibility</span>
              </div>

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Convenience for customers</span>
              </div>

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Hire holatractor operator</span>
              </div>

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Track your tractor with our integrated IOT device</span>
              </div>

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Higher reserves</span>
              </div>

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Credibility and trust</span>
              </div>


              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Marketing and publicity</span>
              </div>

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Payment convenience</span>
              </div>

              <div className="keypoint">
                <img src={Dash} alt="" />
                <span>Customer agent support</span>
              </div>

            </div>

            <div className="para1">
              All features you can access on your own web panel and mobile app to monitor the services. With holatractor services you can not only provide the tractor services but also support the farming community to increase their productivity.
            </div>

            <div className="para1">

            </div>

          </div>
      }

      <div className="right">

        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/grow.jfif" alt="" />

      </div>

    </div>
  )
}

export default SeconsComponent
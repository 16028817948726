import React from 'react'
import './TermsAndCondition.scss'

const TermsAndCondition = () => {
  return (
    <div className='TermsAndCondition'>

        <div className="Container">

            <div className="heading">Terms And Condition</div>

            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident enim facilis error molestias fuga adipisci qui hic numquam cupiditate vel ea corrupti dolor officia illum sunt quae, non cum. Iste inventore illo debitis consectetur, recusandae, eligendi sit fugit architecto ad earum quidem modi. Aut et nisi quam eius dolore voluptates.
            </p>
            
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident enim facilis error molestias fuga adipisci qui hic numquam cupiditate vel ea corrupti dolor officia illum sunt quae, non cum. Iste inventore illo debitis consectetur, recusandae, eligendi sit fugit architecto ad earum quidem modi. Aut et nisi quam eius dolore voluptates.
            </p>

            <div className="heading">1. Acknowledgement</div>

            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident enim facilis error molestias fuga adipisci qui hic numquam cupiditate vel ea corrupti dolor officia illum sunt quae, non cum. Iste inventore illo debitis consectetur, recusandae, eligendi sit fugit architecto ad earum quidem modi. Aut et nisi quam eius dolore voluptates.
            </p>

            <ul>
                <li>list 1</li>
                <li>list 2</li>
                <li>list 3</li>
                <li>list 4</li>
            </ul>

            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident enim facilis error molestias fuga adipisci qui hic numquam cupiditate vel ea corrupti dolor officia illum sunt quae, non cum. Iste inventore illo debitis consectetur, recusandae, eligendi sit fugit architecto ad earum quidem modi. Aut et nisi quam eius dolore voluptates.
            </p>

            <div className="heading">2. Acknowledgement</div>

            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident enim facilis error molestias fuga adipisci qui hic numquam cupiditate vel ea corrupti dolor officia illum sunt quae, non cum. Iste inventore illo debitis consectetur, recusandae, eligendi sit fugit architecto ad earum quidem modi. Aut et nisi quam eius dolore voluptates.
            </p>

            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident enim facilis error molestias fuga adipisci qui hic numquam cupiditate vel ea corrupti dolor officia illum sunt quae, non cum. Iste inventore illo debitis consectetur, recusandae, eligendi sit fugit architecto ad earum quidem modi. Aut et nisi quam eius dolore voluptates.
            </p>

            <div className="heading">3. Acknowledgement</div>

            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident enim facilis error molestias fuga adipisci qui hic numquam cupiditate vel ea corrupti dolor officia illum sunt quae, non cum. Iste inventore illo debitis consectetur, recusandae, eligendi sit fugit architecto ad earum quidem modi. Aut et nisi quam eius dolore voluptates.
            </p>

            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident enim facilis error molestias fuga adipisci qui hic numquam cupiditate vel ea corrupti dolor officia illum sunt quae, non cum. Iste inventore illo debitis consectetur, recusandae, eligendi sit fugit architecto ad earum quidem modi. Aut et nisi quam eius dolore voluptates.
            </p>

        </div>

    </div>
  )
}

export default TermsAndCondition
import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const FirstComponent = () => {

    const [activeContentHeading, setActiveContnentHeading] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const contentArray = activeLanguage === "Español" ? [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Motores potentes",
            para: "Los motores confiables y de bajo consumo de combustible brindan la potencia que necesita para afrontar tareas exigentes."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Cabinas cómodas para el operador",
            para: "Disfrute de asientos ergonómicos, control de clima y visibilidad clara para una experiencia de trabajo más agradable y eficiente."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/listings.png",
            heading: "Sistemas hidráulicos avanzados",
            para: "Controle con precisión los accesorios e implementos para lograr un rendimiento óptimo."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Caracteristicas de seguridad",
            para: "Priorizamos la seguridad del operador con características como estructuras de protección contra vuelcos (ROPS) y luces de advertencia."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/attachment.png",
            heading: "Integración de GPS y agricultura de precisión",
            para: "Muchos tractores son compatibles con tecnologías avanzadas como sistemas de guía GPS y control automatizado de implementos para mayor precisión y eficiencia."
        },
    ]
    :
    [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Powerful Engines",
            para: "Reliable and fuel-efficient engines deliver the power you need to tackle demanding tasks."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Comfortable Operator Cabs",
            para: "Enjoy ergonomic seating, climate control, and clear visibility for a more enjoyable and efficient working experience."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/listings.png",
            heading: "Advanced Hydraulic Systems",
            para: "Precisely control attachments and implements for optimal performance."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Safety Features",
            para: "We prioritize operator safety with features like rollover protection structures (ROPS) and warning lights."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/attachment.png",
            heading: "GPS and Precision Ag Integration",
            para: "Many tractors are compatible with advanced technologies like GPS guidance systems and automated implement control for increased precision and efficiency."
        },
    ]

    return (
        <div className='FirstComponent'>

            <div className="headings">

                <h2>
                    {
                        activeLanguage === "Español" ?
                            "Explore nuestras amplias funciones de tractor"
                            :
                            "Explore Our Extensive Tractor Features"
                    }
                </h2>

                <p>
                    {
                        activeLanguage === "Español" ?
                            "Nuestros tractores vienen equipados con una variedad de características para mejorar su productividad y comodidad."
                            :
                            "Our tractors come equipped with a variety of features to enhance your productivity and comfort"
                    }
                </p>

            </div>

            <div className="Container">

                <div className="left-container">

                    <div className="list-container">
                        {
                            contentArray.map((details, index) => {
                                return (
                                    <div
                                        className='box'
                                        key={index}
                                        onClick={() => { setActiveContnentHeading(index) }}>
                                        <div
                                            className='heading'
                                            style={{
                                                color: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                            }}>
                                            <p>{index + 1 > 9 ? index + 1 : `0${index + 1}`}.</p>
                                            <p>{details.heading}</p>
                                        </div>
                                        <div className='box-bottom' style={{
                                            backgroundColor: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                        }} />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                <div className="right-container">

                    <div className='contents'>

                        <img
                            src={contentArray[activeContentHeading].img}
                            alt="" />

                        <div className="Heading">
                            {contentArray[activeContentHeading].heading}
                        </div>

                        <div className="Paragraph">
                            {contentArray[activeContentHeading].para}
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default FirstComponent
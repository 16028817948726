import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const FourthComponent = () => {

  const { activeLanguage } = useSelector(state => state.Languages)

  return (
    <div className='FourthComponent'>

      <div className='container'>

        <p className='mainHeading'>
          {
            activeLanguage === "Español" ?
              "Invierta en productividad y eficiencia"
              :
              "Invest in Productivity and Efficiency"
          }
        </p>

        <p className='subHeading'>
          {
            activeLanguage === "Español" ?
              "Al elegir un tractor comercial de Holatractor, está invirtiendo en una máquina que se convertirá en un activo vital para su operación. Nuestros tractores ofrecen rendimiento, confiabilidad y avances tecnológicos inigualables para ayudarlo a lograr la máxima productividad y rentabilidad."
              :
              "By choosing a commercial tractor from Holatractor, you're investing in a machine that will become a vital asset to your operation. Our tractors deliver unmatched performance, reliability, and technological advancements to help you achieve maximum productivity and profitability."
          }
        </p>

      </div>

    </div>
  )
}

export default FourthComponent
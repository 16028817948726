import React from "react";
import "./accountHover.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateUserComponent } from "../../../../redux/userLogInComponentShow";

const AccountHover = () => {

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const { activeLanguage } = useSelector(state => state.Languages)

  return (
    <div className="account-hover">
      <div className="user-hover-btn">
        <button
          // onClick={()=> navigate("/login")}
          onClick={() => { dispatch(updateUserComponent()) }}
        >
          {
            activeLanguage === "Español" ?
              "Acceso"
              :
              "Access"
          }
        </button>
        <span>/</span>
        <button onClick={() => navigate("/register")}>
          {
            activeLanguage === "Español" ?
              "Registro"
              :
              "Register"
          }
        </button>
      </div>

      {
        activeLanguage === "Español" ?
          <div className="my-activity">
            <p>Mi actividad</p>
            <div className="activity-list">
              <p> Búsqueda reciente</p>
              <p> visto recientemente</p>
              <p>Preseleccionado</p>
              <p>Contactado</p>
            </div>
          </div>
          :
          <div className="my-activity">
            <p>My activity</p>
            <div className="activity-list">
              <p> Recent Search</p>
              <p> recently seen</p>
              <p>Shortlisted</p>
              <p>Contacted</p>
            </div>
          </div>
      }
    </div>
  );
};

export default AccountHover;

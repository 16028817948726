import React from 'react'

const SxthComponent = () => {
    const eligibilityArray = [
        {
            heading: "ELIGIBILITY CRITERIA",
            listItems: [
                "Indian Resident",
                "Indian Resident",
                "Indian Resident",
                "Indian Resident",
                "Indian Resident",
            ]
        },
        {
            heading: "ELIGIBILITY CRITERIA",
            listItems: [
                "Indian Resident",
                "Indian Resident",
                "Indian Resident",
                "Indian Resident",
                "Indian Resident",
            ]
        },
    ]

    return (
        <div className='SxthComponent'>

            <h1>
                Documents Required & Eligibility Criteria
            </h1>

            <hr />

            <div className="eligibilityDetails">

                {
                    eligibilityArray.map((details, index) => {
                        return (
                            <div className='eligibilityBox' key={index}>

                                <h2>
                                    {details.heading}
                                </h2>

                                <hr />

                                <ul>

                                    {
                                        details.listItems.map((items, i) => {
                                            return (
                                                <li key={i}>
                                                    {items}
                                                </li>
                                            )
                                        })
                                    }

                                </ul>

                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default SxthComponent
import React, { useRef } from 'react'

const FirstComponent = () => {
    let scrl = useRef(null);

    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
    };

    const componentArray = [
        {
            tag: "Customer Experiences",
            imageLink: "https://kore-wordpress.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/04/09120719/Agent-Experiences.png",
            heading: "Happier customers and experiences",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, libero eget tincidunt aliquam, nunc n"
        },
        {
            tag: "Customer Experiences",
            imageLink: "https://kore-wordpress.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/04/09120719/Agent-Experiences.png",
            heading: "Happier customers and experiences",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, libero eget tincidunt aliquam, nunc n"
        },
        {
            tag: "Customer Experiences",
            imageLink: "https://kore-wordpress.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/04/09120719/Agent-Experiences.png",
            heading: "Happier customers and experiences",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, libero eget tincidunt aliquam, nunc n"
        },
        {
            tag: "Customer Experiences",
            imageLink: "https://kore-wordpress.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/04/09120719/Agent-Experiences.png",
            heading: "Happier customers and experiences",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, libero eget tincidunt aliquam, nunc n"
        },
        {
            tag: "Customer Experiences",
            imageLink: "https://kore-wordpress.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/04/09120719/Agent-Experiences.png",
            heading: "Happier customers and experiences",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, libero eget tincidunt aliquam, nunc n"
        },
        {
            tag: "Customer Experiences",
            imageLink: "https://kore-wordpress.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/04/09120719/Agent-Experiences.png",
            heading: "Happier customers and experiences",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, libero eget tincidunt aliquam, nunc n"
        },
        {
            tag: "Customer Experiences",
            imageLink: "https://kore-wordpress.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/04/09120719/Agent-Experiences.png",
            heading: "Happier customers and experiences",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, libero eget tincidunt aliquam, nunc n"
        },
        {
            tag: "Customer Experiences",
            imageLink: "https://kore-wordpress.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/04/09120719/Agent-Experiences.png",
            heading: "Happier customers and experiences",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, libero eget tincidunt aliquam, nunc n"
        },
        {
            tag: "Customer Experiences",
            imageLink: "https://kore-wordpress.s3.us-east-2.amazonaws.com/wp-content/uploads/2024/04/09120719/Agent-Experiences.png",
            heading: "Happier customers and experiences",
            description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, libero eget tincidunt aliquam, nunc n"
        },
    ]

    return (
        <div className='FirstComponent'>

            <div className="topPart">

                <div className="topLeft">

                    <p style={{ color: "#AB0F0C" }}>
                        What we do
                    </p>

                    <h1>
                        We help businesses create engaging and valued interactions
                    </h1>

                    <p style={{ color: "#747474", fontWeight: "500" }}>
                        Kore.ai has been at the forefront of AI for over a decade, bringing intelligent virtual assistants to life in both customer and employee-facing use cases. Our goal is to simplify AI adoption and get you to meaningful business outcomes faster.
                    </p>

                </div>

                <div className="topRight">

                    <div className="Button" onClick={() => { slide(-150) }}>
                        <i className="fa-solid fa-arrow-left"></i>
                    </div>

                    <div className="Button" onClick={() => { slide(+150) }}>
                        <i className="fa-solid fa-arrow-right"></i>
                    </div>

                </div>

            </div>

            <div className="bottomPart" style={{ scrollbarWidth: 'none' }} ref={scrl}>

                {
                    componentArray.map((details, index)=>{
                        return(
                            <div key={index} className='boxDetails'>

                                <p className='tag'>
                                    {details.tag}
                                </p>

                                <div className='box'>

                                    <img src={details.imageLink} alt="" />

                                    <h2>
                                        {details.heading}
                                    </h2>

                                    <p>
                                        {details.description}
                                    </p>

                                </div>

                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default FirstComponent
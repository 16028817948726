import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const FirstComponent = () => {

    const [activeContentHeading, setActiveContnentHeading] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const contentArray = activeLanguage === "Español" ? [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Amplia flota",
            para: "Contamos con una variada selección de tractores comerciales en buen estado, desde caballos de batalla de servicio pesado para proyectos de excavación a gran escala hasta tractores ágiles y maniobrables para espacios reducidos y tareas complejas."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Flexibilidad de pago por uso",
            para: "Adapte sus alquileres a las necesidades específicas de su proyecto. Alquile un tractor por unos días o unos meses: la elección es suya."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/listings.png",
            heading: "Orientación experta",
            para: "Nuestro equipo de especialistas en equipos está aquí para ayudarlo a elegir el tractor y los accesorios adecuados para su proyecto, garantizando una eficiencia y un rendimiento óptimos."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Soporte 24 horas al día, 7 días a la semana",
            para: "Ofrecemos soporte al cliente confiable las 24 horas, para que pueda obtener la asistencia que necesita cuando la necesite, minimizando el tiempo de inactividad y manteniendo su proyecto en marcha."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/attachment.png",
            heading: "Proceso de alquiler simplificado",
            para: "Nuestra plataforma en línea fácil de usar le permite buscar tractores disponibles, solicitar cotizaciones y administrar sus reservas con facilidad."
        },
    ]
    :
    [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Extensive Fleet",
            para: "We boast a diverse selection of well-maintained commercial tractors, from heavy-duty workhorses for large-scale excavation projects to nimble and maneuverable tractors for tight spaces and intricate tasks."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Pay-As-You-Go Flexibility",
            para: "Tailor your rentals to your specific project needs. Rent a tractor for a few days or a few months – the choice is yours."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/listings.png",
            heading: "Expert Guidance",
            para: "Our team of equipment specialists is here to help you choose the right tractor and attachments for your project, ensuring optimal efficiency and performance."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "24/7 Support",
            para: "We offer reliable customer support around the clock, so you can get the assistance you need whenever you need it, minimizing downtime and keeping your project on track."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/attachment.png",
            heading: "Streamlined Rental Process",
            para: "Our user-friendly online platform allows you to browse available tractors, request quotes, and manage your bookings with ease."
        },
    ]

    return (
        <div className='FirstComponent'>

            <div className="headings">

                <h2>
                    {
                        activeLanguage === "Español" ?
                            "Explore nuestras amplias funciones de tractor"
                            :
                            "Why Choose Holatractor for Commercial Tractor Rentals?"
                    }
                </h2>

            </div>

            <div className="Container">

                <div className="left-container">

                    <div className="list-container">
                        {
                            contentArray.map((details, index) => {
                                return (
                                    <div
                                        className='box'
                                        key={index}
                                        onClick={() => { setActiveContnentHeading(index) }}>
                                        <div
                                            className='heading'
                                            style={{
                                                color: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                            }}>
                                            <p>{index + 1 > 9 ? index + 1 : `0${index + 1}`}.</p>
                                            <p>{details.heading}</p>
                                        </div>
                                        <div className='box-bottom' style={{
                                            backgroundColor: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                        }} />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                <div className="right-container">

                    <div className='contents'>

                        <img
                            src={contentArray[activeContentHeading].img}
                            alt="" />

                        <div className="Heading">
                            {contentArray[activeContentHeading].heading}
                        </div>

                        <div className="Paragraph">
                            {contentArray[activeContentHeading].para}
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default FirstComponent
import React, { useEffect, useRef } from "react";
import "./middlebtn.scss";
import { gsap } from "gsap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const MiddleBtn = () => {
  const pink = useRef(null)

  const hoverTL = gsap.timeline();
  hoverTL.pause();

  const activeLanguage = useSelector(state => state.Languages)

  useEffect(() => {
    hoverTL.clear()
    hoverTL.to(pink.current, {
      width: "calc(100% + 1.3em)",
      ease: "Elastic.easeOut(0.25)",
      duration: 0.4,
    });
    hoverTL.to(pink.current, {
      width: "2em",
      left: "calc(100% - 1.45em)",
      ease: "Elastic.easeOut(0.4)",
      duration: 0.6,
    });
  }, [hoverTL]);

  return (
    <div className="wrapper"

    >

      <Link to={"/impact"} style={{ textDecoration: "none" }}>

        <div className="link" onMouseEnter={() => { hoverTL.play() }}
          onMouseLeave={() => { hoverTL.reverse() }}>
          <div className="color" ref={pink}></div>
          <span>
            {
              activeLanguage.activeLanguage === "Español" ?
                "Explorar impacto"
                :
                "Explore impact"
            }
          </span>
          <i className="fa-solid fa-arrow-right"></i>
        </div>

      </Link>

    </div>
  );
};

export default MiddleBtn;

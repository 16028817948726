import React from 'react'
import './ReservationAndRental.scss'
import Banner from './Banner'
import SeventhComponent from './SeventhComponent'
import SecondComponent from './SecondComponent'
import ThirdComponent from './ThirdComponent'

const ReservationAndRental = () => {
  return (
    <div className='ReservationAndRental'>

        <Banner />

        <SeventhComponent />

        <SecondComponent />

        <ThirdComponent />

    </div>
  )
}

export default ReservationAndRental
import React from 'react'
import './MiniTractor.scss'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'

const MiniTractor = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className='MiniTractor'>

            <div className="container">

                {/* <div className="main-heading">
                Tractores de dos ruedas
                </div> */}

                {/* <div className="sub-heading">
                ¡Cómo un tractor de dos ruedas puede potenciarle!
                </div> */}

                <div className="details-box">

                    {
                        activeLanguage === "Español" ?
                            <div className="left-details">

                                <div className="left-heading">
                                    Tractores de dos ruedas
                                </div>

                                <div className="left-paragraph">
                                    Todo agricultor sueña con una cosecha abundante, pero para los pequeños agricultores, los recursos limitados pueden parecer una barrera. Entendemos que los grandes resultados pueden surgir de pequeños comienzos. Por eso defendemos los tractores de dos ruedas, la solución perfecta para la agricultura a pequeña escala.
                                </div>

                                <Link to={'/mini-tractor'}>
                                    <button
                                        name='know more button'>
                                        Ver más
                                    </button>
                                </Link>

                            </div>
                            :
                            <div className="left-details">

                                <div className="left-heading">
                                Two wheel tractors
                                </div>

                                <div className="left-paragraph">
                                Every farmer dreams of a bountiful harvest, but for small farmers, limited resources can seem like a barrier. We understand that big results can come from small beginnings. That's why we advocate two-wheeled tractors, the perfect solution for small-scale agriculture.
                                </div>

                                <Link to={'/mini-tractor'}>
                                    <button
                                        name='know more button'>
                                        See more
                                    </button>
                                </Link>

                            </div>
                    }

                    <div
                        className="right-details">
                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/de8a7ce3-99ba-4454-94f4-ddaecc2c8535+-+copia.webp"
                            alt="mini tractor" />
                    </div>

                </div>

            </div>

        </div>
    )
}

export default MiniTractor
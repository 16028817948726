import React, { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';

const SecondComponent = () => {
    const [expandedState, setExpandedState] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentArray = activeLanguage === "Español" ? [
        {
            tag: "El tamaño y tipo de su operación.",
            details: "Adapte la potencia y las capacidades del tractor a sus necesidades específicas."
        },
        {
            tag: "Las tareas que necesitas realizar",
            details: "Elija un tractor compatible con los accesorios que utilizará con más frecuencia."
        },
        {
            tag: "Presupuesto",
            details: "Los tractores utilitarios vienen en una variedad de precios. Determine su presupuesto y encuentre un tractor que ofrezca las características que necesita dentro de su rango de precios."
        },
    ] : [
        {
            tag: "The size and type of your operation",
            details: "Match the tractor's horsepower and capabilities to your specific needs."
        },
        {
            tag: "The tasks you need to perform",
            details: "Choose a tractor compatible with the attachments you'll be using most frequently."
        },
        {
            tag: "Budget",
            details: "Utility tractors come in a range of price points. Determine your budget and find a tractor that offers the features you need within your price range."
        },
    ]

    return (
        <div className="SecondComponent">

            <div className='left'>

                <img
                    src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/field_agent.webp"
                    alt=""
                    className='w-full 1000px:w-[90%] rounded-[6px]' />

            </div>

            <div className="rigth">

                <div className="heading">
                    {
                        activeLanguage === "Español" ?
                            "Elegir el tractor utilitario adecuado"
                            :
                            "Choosing the Right Utility Tractor"
                    }
                </div>

                <p style={{marginBottom: '20px'}}>
                    {
                        activeLanguage === "Español" ?
                            "Al seleccionar un tractor utilitario, considere estos factores"
                            :
                            "When selecting a utility tractor, consider these factors"
                    }
                </p>

                {
                    componentArray.map((details, index) => {
                        return (
                            <div key={index}>

                                <div className='box-top' onClick={() => { setExpandedState(index) }}>
                                    <span>{details.tag}</span>
                                    <ExpandMoreIcon
                                        style={{
                                            transform: expandedState === index ? 'rotate(180deg)' : 'rotate(0deg)'
                                        }} />
                                </div>

                                <div className='box-bottom'
                                    style={{
                                        height: expandedState === index ? '100px' : '0px',
                                        display: expandedState === index ? 'block' : 'none'
                                    }}>
                                    {details.details}
                                </div>

                            </div>
                        )
                    })
                }

            </div>

        </div>
    )
}

export default SecondComponent
import React from 'react'
import './ServicesTractores.scss'
import Banner from './Banner'
import FirstComponent from './FirstComponent'
import SecondComponent from './SecondComponent'
import ThirdComponent from './ThirdComponent'

const ServicesTractores = () => {
    return (
        <div className='ServicesTractores'>

            <Banner />

            <FirstComponent />

            <SecondComponent />

            <ThirdComponent />

        </div>
    )
}

export default ServicesTractores
import React from 'react'
import serviceBackgroundVideo from '../../../../../assets/video/service.mp4'
import { useSelector } from 'react-redux'

const Banner = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className="Banner">

            <video autoPlay loop muted>
                <source src={serviceBackgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <h1>
                {
                    activeLanguage === "Español" ?
                        "Tractores utilitarios"
                        :
                        "Utility tractors"
                }
            </h1>

            {
                activeLanguage === "Español" ?
                    <p className='paragraphOne'>
                        Los tractores utilitarios son la columna vertebral de muchas operaciones agrícolas y ofrecen el equilibrio perfecto entre potencia, versatilidad y tamaño. No son los tractores más grandes del mercado, pero tienen una gran potencia, lo que los hace ideales para una amplia gama de tareas y terrenos.
                    </p>
                    :
                    <p className='paragraphOne'>
                        Utility tractors are the backbone of many agricultural operations, offering the perfect balance of power, versatility, and size. They're not the biggest tractors on the market, but they pack a serious punch, making them ideal for a wide range of tasks and terrains.
                    </p>
            }

        </div>
    )
}

export default Banner
import React, { useState, useRef } from "react";
import CollectionData, { CollectionDataEnglish } from "./FeatureData";
import "./featurecollection.scss";
import { useSelector } from "react-redux";

const FeatureCollection = () => {
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  const { activeLanguage } = useSelector(state => state.Languages)

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };


  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    <>
      <div className="feature-container">
        <div className="feature-section">
          {
            activeLanguage === "Español" ?
              <p>
                Colección de <span>tractores</span>
              </p>
              :
              <p>
                <span>Tractor </span>collection
              </p>
          }
          <div className="para">
            <p>
            {
            activeLanguage === "Español" ?
              "Tractor se diferencian entre sí"
              :
              "Tractor differ from each other"
          }
           </p>
          </div>
        </div>
        <div className="feature-wrapper">
          {scrollX !== 0 && (
            <button
              className="prev"
              onClick={() => slide(-300)}
            >

              <i className="fa-solid fa-arrow-left"></i>
            </button>
          )}
          <div className="feature-data" ref={scrl} onScroll={scrollCheck}>
            {activeLanguage === "Español" ? CollectionData.map((item, index) => {
              return (
                <div className="data-section" key={index} style={{ cursor: "pointer" }}>
                  <div className="image-section"  >
                    <img src={item.image} alt="aaaa" />
                  </div>
                  <div className="title-section">
                    <div className="property">
                      <p>{item.id} <span>Tractors</span> </p>
                    </div>
                    <div className="tractorInfo">
                      <h4>{item.title}</h4>
                      <p>{item.para}</p>
                    </div>
                  </div>
                </div>
              );
            })
          :
          CollectionDataEnglish.map((item, index) => {
            return (
              <div className="data-section" key={index} style={{ cursor: "pointer" }}>
                <div className="image-section"  >
                  <img src={item.image} alt="aaaa" />
                </div>
                <div className="title-section">
                  <div className="property">
                    <p>{item.id} <span>Tractors</span> </p>
                  </div>
                  <div className="tractorInfo">
                    <h4>{item.title}</h4>
                    <p>{item.para}</p>
                  </div>
                </div>
              </div>
            );
          })}
          </div>
          {!scrolEnd && (
            <button
              className="next"
              onClick={() => slide(+300)}
            >

              <i className="fa-solid fa-arrow-right"></i>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default FeatureCollection;

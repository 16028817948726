import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const Footer = () => {

    const languageOption = useSelector(state => state.language.language)

    return (
        <div className='w-full py-[40px] bg-white relative before:absolute before:top-0 before:w-full before:h-[2px] before:bg-green-600'>

            <div className='w-[90%] 900px:w-[80%] mx-auto flex items-center justify-center'>

                <div className='grid grid-cols-1 900px:grid-cols-4 w-fit 900px:w-full gap-8'>

                    <div>

                        <img
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/logo.png"
                            alt="site logo"
                            className='w-[200px]' />

                        <p className='py-[20px] font-bold text-gray-400'>
                            Subscribe to latest news
                        </p>

                        <div className='w-[300px] 900px:w-full p-[10px] border border-gray-200'>

                            <input 
                            type="email"
                            className='outline-none border-none w-full'
                            placeholder='Enter Your Email Here' />

                        </div>

                    </div>

                    <div className='font-bold text-[18px]'>

                        <p>
                            Our Company
                        </p>

                        <div className='text-gray-400 flex flex-col gap-[10px] mt-[10px]'>

                            <p className='cursor-pointer hover:text-[#028a86] transition-all duration-500'>
                                Home
                            </p>

                            <p className='cursor-pointer hover:text-[#028a86] transition-all duration-500'>
                                About
                            </p>

                            <p className='cursor-pointer hover:text-[#028a86] transition-all duration-500'>
                                Industry
                            </p>

                           

                            <p className='cursor-pointer hover:text-[#028a86] transition-all duration-500'>
                                Contact
                            </p>

                        </div>

                    </div>

                    <div className='font-bold text-[18px]'>

                    {
                                languageOption === 'English' ?
                                    'Services'
                                    :
                                    'Servicios'
                            }

                        <div className='text-gray-400 flex flex-col gap-[10px] mt-[10px]'>

                            <p className='cursor-pointer hover:text-[#028a86] transition-all duration-500'>
                            {
                                    languageOption === 'English' ?
                                        'Palm Re-collection'
                                        :
                                        'Recolección de palmas'
                                }
                            </p>

                            <Link 
                            to={'/regenerative-agriculture'} 
                            className='cursor-pointer hover:text-[#028a86] transition-all duration-500'>
                            {
                                    languageOption === 'English' ?
                                        'Regenerative Agriculture'
                                        :
                                        'Agricultura Regenerativa'
                                }
                            </Link>

                            <p className='cursor-pointer hover:text-[#028a86] transition-all duration-500'>
                            {
                                    languageOption === 'English' ?
                                        'Acai recollection'
                                        :
                                        'Recuerdo de acai'
                                }
                            </p>

                        </div>

                    </div>

                    <div className='font-bold text-[18px]'>

                        <p className='font-bold text-[18px]'>
                            Solutions
                        </p>

                        <div className='text-gray-400 flex flex-col gap-[10px] mt-[10px]'>

                            <p className='cursor-pointer hover:text-[#028a86] transition-all duration-500'>
                                IOT solution
                            </p>

                            <p className='cursor-pointer hover:text-[#028a86] transition-all duration-500'>
                                2 wheeler small tractor
                            </p>

                            <p className='cursor-pointer hover:text-[#028a86] transition-all duration-500'>
                                4 wheeler tractor
                            </p>

                            <p className='cursor-pointer hover:text-[#028a86] transition-all duration-500'>
                                Tractor attachments
                            </p>

                            <p className='cursor-pointer hover:text-[#028a86] transition-all duration-500'>
                                SAAS solution
                            </p>

                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default Footer
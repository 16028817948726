import { createSlice } from "@reduxjs/toolkit";

export const UserSlice = createSlice({
  name: "User",

  initialState: sessionStorage.getItem("User")
    ? JSON.parse(sessionStorage.getItem("User"))
    : {
        real_id: 0,
        user_id: "",
        name: "",
        phone_number: "",
        email_address: "",
        adharid: "",
        panid: "",
        address: "",
        linked_in: "",
        image: ""
      },

  reducers: {

    updateUser: (state, action) => {
      state.real_id = action.payload.real_id;
      state.user_id = action.payload.user_id;
      state.name = action.payload.name;
      state.phone_number= action.payload.phone_number;
      state.email_address= action.payload.email_address;
      state.adharid= action.payload.adharid;
      state.panid= action.payload.panid;
      state.address= action.payload.address;
      state.linked_in= action.payload.linked_in;
      state.image= action.payload.image;
    },

    LogOut: (state, action) => {
      state.real_id = action.payload.real_id;
      state.user_id = action.payload.user_id;
      state.name = action.payload.name;
      state.phone_number= action.payload.phone_number;
      state.email_address= action.payload.email_address;
      state.adharid= action.payload.adharid;
      state.panid= action.payload.panid;
      state.address= action.payload.address;
      state.linked_in= action.payload.linked_in;
      state.image= action.payload.image;
    }

  },
});

export const { updateUser, LogOut } = UserSlice.actions;

export default UserSlice.reducer;

import { useRef } from 'react'
import EastIcon from '@mui/icons-material/East';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Android from "../../assets/booking/android.png"
import React from "../../assets/booking/physics.png"
import Cloud from "../../assets/booking/cloud-computing.png"
import IOT from "../../assets/booking/iot.png"
import AI from "../../assets/booking/artificial.png"
import { useSelector } from 'react-redux';

const Service = () => {
    let scrl = useRef(null);

    const { activeLanguage } = useSelector(state => state.Languages)

    const vehicleInformationArray = activeLanguage === "Español" ? [
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/booking/plow.webp",
            heading: "PREPARACIÓN DEL TERRENO",
            paragraph: "Dar la vuelta al suelo para airearlo, controlar las malezas y alisar la superficie del suelo, nivelarlo y prepararlo para la siembra."
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/booking/seeding.webp",
            heading: "PLANTACIÓN Y SIEMBRA",
            paragraph: "Colocar semillas o plántulas en el suelo a la profundidad y espaciamiento adecuados. Distribuir o sembrar semillas de manera uniforme sobre un campo."
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/booking/cropcare.webp",
            heading: "CUIDADO DE CULTIVOS",
            paragraph: "Controlar las malezas y aflojar el suelo alrededor de las plantas.Aplicar pesticidas, herbicidas o fungicidas para proteger los cultivos de plagas y enfermedades."
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/booking/transport.webp",
            heading: "TRANSPORTE",
            paragraph: "Transporte de cultivos, equipos o suministros hacia y desde la campo.Mover materiales hacia y desde remolques u otros equipos."
        },
    ] : [
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/booking/plow.webp",
            heading: "GROUND PREPARATION",
            paragraph: "Turn the soil to aerate it, control weeds and smooth the soil surface, level it and prepare it for planting."
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/booking/seeding.webp",
            heading: "PLANTING AND SOWING",
            paragraph: "Place seeds or seedlings in the soil at the proper depth and spacing. Distribute or sow seeds evenly over a field."
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/booking/cropcare.webp",
            heading: "CROPS CARE",
            paragraph: "Control weeds and loosen the soil around plants. Apply pesticides, herbicides or fungicides to protect crops from pests and diseases."
        },
        {
            imgUrl: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/booking/transport.webp",
            heading: "TRANSPORT",
            paragraph: "Transport crops, equipment or supplies to and from the field. Move materials to and from trailers or other equipment."
        },
    ]

    const slide = (shift) => {
        scrl.current.scrollLeft += shift;
    };

    return (
        <div className='service'>

            {
                activeLanguage === "Español" ?
                    <div className="container">

                        <div className="left">
                            <h2>
                                Nuestros servicios con
                            </h2>
                            <div className="subHeading">
                                HOLATRACTOR?
                                <div className="arrowIcon">
                                    <EastIcon />
                                </div>
                            </div>
                        </div>

                        <div className="right">
                            <p>
                            Holatractor ofrece un servicio de alquiler de tractores sin complicaciones diseñado para satisfacer sus necesidades específicas. Ya sea agricultor, propietario de tierras o contratista, nuestros tractores de alta calidad están disponibles para alquiler a precios competitivos.
                            </p>
                        </div>

                    </div>
                    :
                    <div className="container">

                        <div className="left">
                            <h2>
                                Our services with
                            </h2>
                            <div className="subHeading">
                                HOLATRACTOR
                                <div className="arrowIcon">
                                    <EastIcon />
                                </div>
                            </div>
                        </div>

                        <div className="right">
                            <p>
                            Holatractor offers a hassle-free tractor rental service designed to meet your specific needs. Whether you're a farmer, landowner, or contractor, our high-quality tractors are available for rental at competitive rates.
                            </p>
                        </div>

                    </div>
            }

            <div className="imagecontainer">

                <div className='below-headings-section'>

                    <div className="scrolling-button-section">

                        <div className="Button" onClick={() => { slide(-150) }}>
                            <i className="fa-solid fa-arrow-left"></i>
                        </div>

                        <div className="Button" onClick={() => { slide(+150) }}>
                            <i className="fa-solid fa-arrow-right"></i>
                        </div>

                    </div>

                </div>

                <div className="image-container" style={{ scrollbarWidth: 'none' }} ref={scrl}>

                    {
                        vehicleInformationArray.map((details, index) => {
                            return (
                                <div className="image-box" key={index}>

                                    <div className="imageBg">

                                        <img
                                            src={details.imgUrl}
                                            alt="vehicle image"
                                            className='image-section' />

                                    </div>

                                    <h2>
                                        {details.heading}
                                    </h2>

                                    <p>
                                        {details.paragraph}
                                    </p>

                                    <button
                                        name='know more button'>
                                        {
                                            activeLanguage === "Español" ?
                                                "PRUÉBALO AHORA"
                                                :
                                                "TRY IT NOW"
                                        }
                                    </button>

                                </div>
                            )
                        })
                    }

                </div>

            </div>

            <div className="technologyContainer">

                <img
                    src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/booking/tractor_tech.webp"
                    alt="technology image" />

                <div className='wrapper' />

                {
                    activeLanguage === "Español" ?
                        <div className="content">
                            <h2>APROVECHA LA TECNOLOGÍA AVANZADA</h2>
                            <h3>BY HOLATRACTOR</h3>
                        </div>
                        :
                        <div className="content">
                            <h2>TAKE ADVANTAGE OF ADVANCED TECHNOLOGY</h2>
                            <h3>BY HOLATRACTOR</h3>
                        </div>
                }

                <div className="arrowbutton">
                    <div className="arrowcontainer">
                        <KeyboardArrowDownIcon />
                    </div>
                </div>

            </div>

            <div className="techIcons">
                <img src={Android} alt="icon" />
                <img src={React} alt="icon" />
                <img src={Cloud} alt="icon" />
                <img src={IOT} alt="icon" />
                <img src={AI} alt="icon" />
            </div>

        </div>
    )
}

export default Service
import React from "react";
import "./homeMiddle.scss";
import MiddleBtn from "./MiddleButton/MiddleBtn";
import { useSelector } from "react-redux";

const HomeMiddle = () => {

  const activeLanguage = useSelector(state => state.Languages)

  return (
    <div className="home-middle">
      <div className="left-middle">
        {/* <iframe
          width="445"
          height="250"
          src="https://www.youtube.com/embed/CHeSlbm1brg"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe> */}
        <iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fholatractor%2Fvideos%2F991073688753606%2F&show_text=0&width=500" width="500" height="280" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameBorder="0" allowtransparency="true" allowFullScreen={true}></iframe>

      </div>
      <div className="right-middle">
        <div className="heading">
          <h1>
            {
              activeLanguage.activeLanguage === "Español" ?
                "Construyamos juntos holatractor"
                :
                "Let's build holatractor together"
            }
          </h1>
          <h3>
            {
              activeLanguage.activeLanguage === "Español" ?
                "Brindamos solución integral en servicios de tractores"
                :
                "We provide a comprehensive solution in tractor services"
            }
          </h3>
          <p>
            {
              activeLanguage.activeLanguage === "Español" ?
                "Agricultores | Dueño del tractor | Agentes | Operadores"
                :
                "Farmers | Tractor owner | Agents | Operators"
            }

          </p>
        </div>
        <div className="middle-btn">
          <MiddleBtn />
        </div>
      </div>
    </div>
  );
};

export default HomeMiddle;

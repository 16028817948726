import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'

const ThirdComponent = () => {

    const [activeContentHeading, setActiveContnentHeading] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const contentArray = activeLanguage === "Español" ? [
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
            heading: "Limpieza de terrenos y paisajismo",
            para: "Alquile un tractor potente con una retroexcavadora para limpiar terrenos, cavar zanjas o mover materiales para proyectos de paisajismo."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Preparación y cultivo del campo",
            para: "Labra, ara y prepara tus campos para la siembra con un tractor equipado con los implementos adecuados."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "Acarreo y transporte",
            para: "Mueva materiales pesados, escombros o equipos con una combinación de tractor y remolque."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
            heading: "Remoción de nieve",
            para: "Afronte incluso las nevadas más intensas con un tractor equipado con un accesorio quitanieves."
        },
        {
            img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
            heading: "¡Y muchos más!",
            para: "Nuestros tractores se pueden utilizar para una variedad de tareas, desde proyectos agrícolas y pequeños trabajos de construcción hasta mantenimiento de propiedades y necesidades ocasionales de trabajo pesado."
        },
    ]
        :
        [
            {
                img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/profile1.png",
                heading: "Land Clearing & Landscaping",
                para: "Rent a powerful tractor with a backhoe attachment to clear land, dig trenches, or move materials for landscaping projects."
            },
            {
                img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
                heading: "Field Preparation & Cultivation",
                para: "Till, plow, and prepare your fields for planting with a tractor equipped with the appropriate implements."
            },
            {
                img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
                heading: "Hauling & Transportation",
                para: "Move heavy materials, debris, or equipment with a tractor and trailer combination."
            },
            {
                img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/community_join.png",
                heading: "Snow Removal",
                para: "Tackle even the heaviest snowfall with a tractor equipped with a snowblower attachment."
            },
            {
                img: "https://holaimagesdata.s3.us-west-2.amazonaws.com/web/community/select_tractor.png",
                heading: "And Many More!",
                para: "Our tractors can be used for a variety of tasks, from agricultural projects and small construction jobs to property maintenance and occasional heavy-duty needs."
            },
        ]

    return (
        <div className='ThirdComponent'>

            <div className="headings">

                <h2>
                    {
                        activeLanguage === "Español" ?
                            "Ideal para varios proyectos"
                            :
                            "Ideal for Various Projects"
                    }
                </h2>

            </div>

            <div className="Container">

                <div className="left-container">

                    <div className="list-container">
                        {
                            contentArray.map((details, index) => {
                                return (
                                    <div
                                        className='box'
                                        key={index}
                                        onClick={() => { setActiveContnentHeading(index) }}>
                                        <div
                                            className='heading'
                                            style={{
                                                color: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                            }}>
                                            <p>{index + 1 > 9 ? index + 1 : `0${index + 1}`}.</p>
                                            <p>{details.heading}</p>
                                        </div>
                                        <div className='box-bottom' style={{
                                            backgroundColor: activeContentHeading === index ? 'white' : 'rgba(255, 255, 255, 0.497)'
                                        }} />
                                    </div>
                                )
                            })
                        }
                    </div>

                </div>

                <div className="right-container">

                    <div className='contents'>

                        <img
                            src={contentArray[activeContentHeading].img}
                            alt="" />

                        <div className="Heading">
                            {contentArray[activeContentHeading].heading}
                        </div>

                        <div className="Paragraph">
                            {contentArray[activeContentHeading].para}
                        </div>

                    </div>

                </div>

            </div>

        </div>
    )
}

export default ThirdComponent
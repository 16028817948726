import React from 'react'
import './booking.scss'
import Hero from './Hero'
import Visit from './Visit'
import About from "./About"
import Service from "./Service"
import Process from './Process'
import ConnectSection from "./ConnectSection"
import Technology from "./Technology"

const Booking = () => {
  return (
    <div
    className="booking">
      <Hero />
      <Visit />
      <About />
      <Service />
      <Technology />
      <Process />
      <ConnectSection />
    </div>
  )
}

export default Booking
import React from 'react'
import { useSelector } from 'react-redux'

const SeventhComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentArray = activeLanguage === "Español" ? [
        {
            heading: "Soporte experto",
            description: "Nuestro dedicado equipo de especialistas en productos puede ayudarlo a elegir el tractor comercial adecuado para sus necesidades y aplicaciones específicas.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/market_disturption.png"
        },
        {
            heading: "Servicio excepcional",
            description: "Ofrecemos soporte posventa integral, que incluye repuestos, mantenimiento y asistencia técnica para mantener sus tractores funcionando de manera óptima.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/cutomers_expect.png"
        },
        {
            heading: "Opciones de financiación",
            description: "Explore soluciones de financiación flexibles que se ajusten a su presupuesto y haga realidad la propiedad de un potente tractor comercial para su operación.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/operational.png"
        },
    ]
        :
        [
            {
                heading: "Expert Support",
                description: "Our dedicated team of product specialists can help you choose the right commercial tractor for your specific needs and applications.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/market_disturption.png"
            },
            {
                heading: "Exceptional Service",
                description: "We offer comprehensive after-sales support, including parts, maintenance, and technical assistance to keep your tractors running optimally.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/cutomers_expect.png"
            },
            {
                heading: "Financing Options",
                description: "Explore flexible financing solutions to fit your budget and make owning a powerful commercial tractor a reality for your operation.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/operational.png"
            },
        ]

    return (
        <div
            className='SeventhComponent'>

            <div
                className='seventh_container'
                style={{
                    // background: 'radial-gradient(50% 50% at 50% 50%, rgba(158, 110, 230, 0.28) 0%, rgba(158, 110, 230, 0) 100%)'
                }}>

                <h1
                    className='text-3xl 1000px:text-5xl'>
                    {
                        activeLanguage === "Español" ?
                            "Ventajas de Holatractor"
                            :
                            "Holatractor Advantage"
                    }
                </h1>

                <div
                    className='grid-cols-1 800px:grid-cols-2 data_container'>

                    {
                        componentArray.map((details, index) => {
                            return (
                                <div
                                    key={index}
                                    className='data_box'>

                                    <img
                                        alt={details.heading}
                                        src={details.imageLink}
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            objectFit: 'cover'
                                        }} />

                                    <div
                                        className='text-lg 1000px:text-2xl data_text_container'>

                                        <p>
                                            <b>
                                                {details.heading}
                                            </b>
                                        </p>

                                        <p>
                                            {details.description}
                                        </p>

                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

            </div>

        </div>
    )
}

export default SeventhComponent
import React, { useEffect, useState } from 'react'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import EnglandFlag from '../../../assest/icons/england-flag.png'
import { useDispatch, useSelector } from 'react-redux';
import { update } from '../../redux/languageSlice';
import ContactBox from '../ContactBox/ContactBox';
import { updateContactPageShow } from '../../redux/ContactPageShow'
import { Link } from 'react-router-dom'

const Navbar = () => {

    const [menuOpen, setMenuOpen] = useState(false)
    const [subMenuShow, setSubMenuShow] = useState('')
    const [hoverComponent, setHoverComponent] = useState('')
    // const [languageOption, setLanguageOption] = useState('Spanish')
    const [scrollReached, setScrollReached] = useState(false)

    const dispatch = useDispatch()

    // useEffect(() => {
    //     dispatch(update({ languageOption }))
    // }, [languageOption])

    const showContactForm = useSelector(state => state.ContactPageShow)
    const languageOption = useSelector(state => state.language.language)
    // console.log(showContactForm)

    function handleShowContactForm(e) {
        e.stopPropagation();
        dispatch(updateContactPageShow())
    }

    const handleScroll = () => {
        if (window.scrollY >= 100) {
            setScrollReached(true);
        } else {
            setScrollReached(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // return () => {
        //   window.removeEventListener('scroll', handleScroll);
        // };
    }, []);

    return (
        <div 
        className={`w-full h-[10vh] fixed top-0 right-0 z-[50] drop-shadow-md transition-all duration-500 ${scrollReached ? 'bg-white' : 'bg-white 900px:bg-transparent'}`}>

            {
                showContactForm.show &&
                <ContactBox />
            }

            <div className='w-[80%] flex items-center justify-between py-[20px] mx-auto h-full'>

                <div>
                    <img
                        src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/palm/logo.png"
                        alt="site logo"
                        className='h-[9vh]' />
                </div>

                <div className='font-[600] items-center gap-[30px] hidden 900px:flex'>

                    <Link
                        to={'/regenerative-agriculture'}
                        className='cursor-pointer text-[#23A455] hover:text-[#61CE70] transition-all duration-500 text-[20px] group'>
                        {
                            languageOption === 'English' ?
                                'Home'
                                :
                                'Hogar'
                        }
                        <div
                            className='w-full h-[3px] relative before:absolute before:top-0 before:left-0 before:w-0 before:h-full group-hover:before:w-full group-hover:bg-blue-500 group-hover:before:transition-all group-hover:before:duration-500 transition-all duration-500' />
                    </Link>

                    <div
                        className='cursor-pointer text-[#23A455] hover:text-[#61CE70] transition-all duration-500 group relative'
                        onMouseEnter={() => { setHoverComponent('PRODUCTS') }}
                        onMouseLeave={() => { setHoverComponent('') }}>

                        <span className='text-[20px]'>
                            {
                                languageOption === 'English' ?
                                    'Services'
                                    :
                                    'Servicios'
                            }
                        </span>
                        <PlayArrowIcon className='rotate-90 group-hover:-rotate-90' />
                        <div
                            className='w-full h-[4px] relative before:absolute before:top-0 before:left-0 before:w-0 before:h-full group-hover:before:w-full group-hover:bg-blue-500 group-hover:before:transition-all group-hover:before:duration-500 transition-all duration-500' />

                        <div
                            className={`absolute bottom-[-159px] group-hover:flex ${hoverComponent === 'PRODUCTS' ? 'flex' : 'hidden'} z-[100] w-[260px] h-[160px] bg-white text-black px-[20px] pt-[20px] flex-col gap-[20px] font-[400]`}
                            onMouseEnter={() => { setHoverComponent('PRODUCTS') }}
                            onMouseLeave={() => { setHoverComponent('') }}
                        >

                            <Link 
                            to={'/regenerative-agriculture/palm-recollection'}
                            className='hover:text-[#23A455]'>
                                {
                                    languageOption === 'English' ?
                                        'Palm Re-collection'
                                        :
                                        'Recolección de palmas'
                                }
                            </Link>
                            <Link
                                to={'/regenerative-agriculture/regenerative-agriculture'}
                                className='hover:text-[#23A455]'>
                                {
                                    languageOption === 'English' ?
                                        'Regenerative Agriculture'
                                        :
                                        'Agricultura Regenerativa'
                                }
                            </Link>
                            <Link 
                            to={'/regenerative-agriculture/acai'}
                            className='hover:text-[#23A455]'>
                                {
                                    languageOption === 'English' ?
                                        'Acai recollection'
                                        :
                                        'Recuerdo de acai'
                                }
                            </Link>

                        </div>

                    </div>

                    <Link
                        to={'/regenerative-agriculture/pricing'}
                        className='cursor-pointer text-[#23A455] hover:text-[#61CE70] transition-all duration-500 group relative'>

                        <span className='text-[20px]'>
                            {
                                languageOption === 'English' ?
                                    'Pricing'
                                    :
                                    'Precios'
                            }
                        </span>
                        <div
                            className='w-full h-[3px] relative before:absolute before:top-0 before:left-0 before:w-0 before:h-full group-hover:before:w-full group-hover:bg-blue-500 group-hover:before:transition-all group-hover:before:duration-500 transition-all duration-500' />

                    </Link>

                    <Link
                        to={'/regenerative-agriculture/community'}
                        className='cursor-pointer text-[#23A455] hover:text-[#61CE70] transition-all duration-500 group relative'>

                        <span className='text-[20px]'>
                            {
                                languageOption === 'English' ?
                                    'Community'
                                    :
                                    'Comunidad'
                            }
                        </span>
                        <div
                            className='w-full h-[3px] relative before:absolute before:top-0 before:left-0 before:w-0 before:h-full group-hover:before:w-full group-hover:bg-blue-500 group-hover:before:transition-all group-hover:before:duration-500 transition-all duration-500' />

                    </Link>

                    <div
                        className='cursor-pointer text-[#23A455] hover:text-[#61CE70] transition-all duration-500 group relative'>

                        <span className='text-[20px]'>
                            {
                                languageOption === 'English' ?
                                    'About'
                                    :
                                    'Acerca de'
                            }
                        </span>
                        <div
                            className='w-full h-[3px] relative before:absolute before:top-0 before:left-0 before:w-0 before:h-full group-hover:before:w-full group-hover:bg-blue-500 group-hover:before:transition-all group-hover:before:duration-500 transition-all duration-500' />

                    </div>

                    <select
                        className='cursor-pointer text-[#23A455] hover:text-[#61CE70] transition-all duration-500 group relative text-[20px] outline-none border-none bg-transparent'
                        onChange={e => { dispatch(update({ languageOption: e.target.value })) }}
                        defaultValue={languageOption}>

                        <option className='text-[20px]' value={'English'}>English</option>
                        <option className='text-[20px]' value={'Spanish'}>Spanish</option>

                    </select>

                    <button
                        className='text-white bg-[#23A455] px-[30px] py-[10px] rounded-full font-bold'
                        onClick={(e) => { handleShowContactForm(e) }}>
                        <span className='text-[20px]'>
                            {
                                showContactForm.show ?
                                    <ClearIcon />
                                    :
                                    languageOption === 'English' ?
                                        'Contact'
                                        :
                                        'Contacto'

                            }
                        </span>
                    </button>

                </div>

                <div className='block 900px:hidden'>
                    {
                        menuOpen ?
                            <ClearIcon onClick={() => { setMenuOpen(prev => !prev) }} />
                            :
                            <MenuIcon onClick={() => { setMenuOpen(prev => !prev) }} />
                    }
                </div>

            </div>

            {
                menuOpen &&
                <div
                    className='absolute h-[90vh] bottom-[-90vh] w-full bg-white z-50 p-[20px] box-border text-[#028a86] text-[20px]'>

                    <div className='flex flex-col gap-[10px] w-[80%] mx-auto'>

                        {
                            languageOption === 'English' ?
                                'Home'
                                :
                                'Hogar'
                        }

                        <div className='w-full flex items-center justify-between' onClick={() => { setSubMenuShow('PRODUCT') }}>
                            <span>
                                {
                                    languageOption === 'English' ?
                                        'Services'
                                        :
                                        'Servicios'
                                }
                            </span>
                            <KeyboardArrowDownIcon />
                        </div>

                        {
                            subMenuShow === 'PRODUCT' &&
                            <div className='ml-[10px] text-[16px] flex flex-col gap-[10px]'>

                                <Link 
                                to={'/regenerative-agriculture/palm-recollection'}>
                                    {
                                        languageOption === 'English' ?
                                            'Palm Re-collection'
                                            :
                                            'Recolección de palmas'
                                    }
                                </Link>
                                <Link
                                    to={'/regenerative-agriculture/regenerative-agriculture'}>
                                    {
                                        languageOption === 'English' ?
                                            'Regenerative Agriculture'
                                            :
                                            'Agricultura Regenerativa'
                                    }
                                </Link>
                                <Link
                                to={'/regenerative-agriculture/acai'}>
                                    {
                                        languageOption === 'English' ?
                                            'Acai recollection'
                                            :
                                            'Recuerdo de acai'
                                    }
                                </Link>

                            </div>
                        }

                        <Link
                        to={'/regenerative-agriculture/pricing'}
                        className='w-full flex items-center justify-between' 
                        onClick={() => { setSubMenuShow('SOLUTION') }}>
                            <span>
                                {
                                    languageOption === 'English' ?
                                        'Pricing'
                                        :
                                        'Precios'
                                }
                            </span>
                        </Link>

                        <Link
                            to={'/regenerative-agriculture/community'}
                            className='cursor-pointer text-[#23A455] hover:text-[#61CE70] transition-all duration-500 group relative'>

                            <span className='text-[20px]'>
                                {
                                    languageOption === 'English' ?
                                        'Community'
                                        :
                                        'Comunidad'
                                }
                            </span>
                            <div
                                className='w-full h-[3px] relative before:absolute before:top-0 before:left-0 before:w-0 before:h-full group-hover:before:w-full group-hover:bg-blue-500 group-hover:before:transition-all group-hover:before:duration-500 transition-all duration-500' />

                        </Link>

                        <div>
                            {
                                languageOption === 'English' ?
                                    'About'
                                    :
                                    'Acerca de'
                            }
                        </div>

                        <div>

                            <select
                                className='cursor-pointer text-[#23A455] hover:text-[#61CE70] transition-all duration-500 group relative text-[20px] outline-none border-none bg-transparent'
                                onChange={e => { dispatch(update({ languageOption: e.target.value })) }}
                                defaultValue={languageOption}>

                                <option className='text-[20px]' value={'English'}>English</option>
                                <option className='text-[20px]' value={'Spanish'}>Spanish</option>

                            </select>

                        </div>

                    </div>

                </div>
            }

        </div>
    )
}

export default Navbar
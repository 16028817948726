import React from 'react'
import serviceBackgroundVideo from '../../../../../assets/video/service.mp4'
import { useSelector } from 'react-redux'

const Banner = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className="Banner">

            <video autoPlay loop muted>
                <source src={serviceBackgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <h1>
                {
                    activeLanguage === "Español" ?
                        "El alquiler de tractores es fácil con Holatractor"
                        :
                        "Tractor Rentals Made Easy with Holatractor"
                }
            </h1>

            {
                activeLanguage === "Español" ?
                    <p className='paragraphOne'>
                        Creemos que todos merecen acceso a las herramientas que necesitan para cultivar un paisaje próspero. Por eso, en holatractor Community Rentals ofrecemos un servicio único de alquiler de tractores de pago por uso que se adapta perfectamente a tus necesidades y presupuesto.
                    </p>
                    :
                    <p className='paragraphOne'>
                        we believe that everyone deserves access to the tools they need to cultivate a thriving landscape. That's why At holatractor Community Rentals, we offer a unique <b style={{color: "#AB0F0C"}}>pay-as-you-go tractor rental service </b>that perfectly fits your needs and budget.
                    </p>
            }

        </div>
    )
}

export default Banner
import React from 'react'
import FirstComponent from './FirstComponent'
import Banner from './Banner'
import './CommercialBooking.scss'

const CommercialBooking = () => {
  return (
    <div className='CommercialBooking'>

      <Banner />

      <FirstComponent />

    </div>
  )
}

export default CommercialBooking
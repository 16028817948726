import React from 'react'

const SeventhComponent = () => {
    const componentArray = [
        {
            heading: "Reduced Resistance",
            description: "Proactive communication and clear benefits minimize resistance and encourage greater adoption of new technologies.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/market_disturption.png"
        }, 
        {
            heading: "Increased Employee Engagement",
            description: "When employees feel included and valued, they are more likely to embrace change and become active participants in the process.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/cutomers_expect.png"
        }, 
        {
            heading: "Improved Project Success Rates",
            description: "Effective change management reduces friction and setbacks, leading to a higher chance of successful digital transformation projects.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/operational.png"
        }, 
        {
            heading: "Sustainable Transformation",
            description: "By fostering a culture of continuous learning and adaptation, your organization becomes more resilient and adaptable to future changes.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/data_decission.png"
        }, 
    ]

    return (
        <div
            className='SeventhComponent'>

            <div
                className='seventh_container'
                style={{
                    // background: 'radial-gradient(50% 50% at 50% 50%, rgba(158, 110, 230, 0.28) 0%, rgba(158, 110, 230, 0) 100%)'
                }}>

                <h1
                    className='text-3xl 1000px:text-5xl'>
                    Benefits of Effective Change Management
                </h1>

                <div
                    className='grid-cols-1 800px:grid-cols-2 data_container'>

                    {
                        componentArray.map((details, index) => {
                            return (
                                <div
                                    key={index}
                                    className='data_box'>

                                        <img
                                        alt={details.heading}
                                        src={details.imageLink}
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            objectFit: 'cover'
                                        }} />

                                    <div
                                        className='text-lg 1000px:text-2xl data_text_container'>

                                        <p>
                                            <b>
                                            {details.heading}
                                            </b>
                                        </p>

                                        <p>
                                            {details.description}
                                        </p>

                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

            </div>

        </div>
    )
}

export default SeventhComponent
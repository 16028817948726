import React from 'react'

const ThirdComponent = () => {
  return (
    <div className='ThirdComponent'>

        <div
        className='leftSide'>

            <h1>
            Fast Service During Emergency
            </h1>

            <p>
            We provide an instant loan in a few hours because our professional and experienced staff provide you with the best services in an emergency loan requirement. Less paperwork and quick approval of the amount.
            </p>

        </div>

        <img src="https://www.xpressloan.in/assets/img/why%20choose%20us%20pic-01.png" alt="" />

    </div>
  )
}

export default ThirdComponent
import React from 'react'
import { useSelector } from 'react-redux'

const SeventhComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentArray = activeLanguage === "Español" ? [
        {
            heading: "Diseñado para agricultores",
            description: "Nuestra diversa flota incluye una amplia gama de tractores y accesorios perfectos para cada etapa de su operación agrícola, desde la labranza y la siembra hasta la cosecha y el transporte.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/market_disturption.png"
        },
        {
            heading: "Comodidad de pago por uso",
            description: "Paga sólo por el tiempo que realmente utilices el equipo. Este modelo flexible le permite adaptar sus necesidades de equipo a sus tareas y presupuesto específicos.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/cutomers_expect.png"
        },
        {
            heading: "Impulsar la gestión de recursos",
            description: "Alquile el tractor perfecto para cada trabajo, eliminando la necesidad de una flota grande y costosa.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/operational.png"
        },
        {
            heading: "Precios transparentes",
            description: "Consulte nuestra estructura de precios por adelantado antes de reservar, para que pueda planificar sus gastos con confianza. Sin cargos ocultos, solo la potencia que necesitas a un costo predecible.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/data_decission.png"
        },
    ]
        :
        [
            {
                heading: "Tailored for Farmers",
                description: "Our diverse fleet includes a wide range of tractors and attachments perfect for every stage of your farming operation – from tilling and planting to harvesting and hauling.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/market_disturption.png"
            },
            {
                heading: "Pay-Per-Use Convenience",
                description: "Only pay for the time you actually use the equipment. This flexible model allows you to match your equipment needs to your specific tasks and budget.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/cutomers_expect.png"
            },
            {
                heading: "Boost Resource Management",
                description: "Rent the perfect tractor for each job, eliminating the need for a large, expensive fleet.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/operational.png"
            },
            {
                heading: "Transparent Pricing",
                description: "See our upfront pricing structure before you book, so you can plan your expenses with confidence. No hidden fees, just the power you need at a predictable cost.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/data_decission.png"
            },
        ]

    return (
        <div
            className='SeventhComponent'>

            <div
                className='seventh_container'
                style={{
                    // background: 'radial-gradient(50% 50% at 50% 50%, rgba(158, 110, 230, 0.28) 0%, rgba(158, 110, 230, 0) 100%)'
                }}>

                <h1
                    className='text-3xl 1000px:text-5xl'>
                    {
                        activeLanguage === "Español" ?
                            "Cultivar la eficiencia"
                            :
                            "Cultivate Efficiency"
                    }
                </h1>

                <div
                    className='grid-cols-1 800px:grid-cols-2 data_container'>

                    {
                        componentArray.map((details, index) => {
                            return (
                                <div
                                    key={index}
                                    className='data_box'>

                                    <img
                                        alt={details.heading}
                                        src={details.imageLink}
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            objectFit: 'cover'
                                        }} />

                                    <div
                                        className='text-lg 1000px:text-2xl data_text_container'>

                                        <p>
                                            <b>
                                                {details.heading}
                                            </b>
                                        </p>

                                        <p>
                                            {details.description}
                                        </p>

                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

            </div>

        </div>
    )
}

export default SeventhComponent
import React from 'react'
import Navbar from '../../palmComponents/Navbar/Navbar'
import AcaiComponentOne from '../../palmComponents/AcaiComponents/AcaiComponentOne'
import AcaiComponentTwo from '../../palmComponents/AcaiComponents/AcaiComponentTwo'
import AcaiComponentThree from '../../palmComponents/AcaiComponents/AcaiComponentThree'
import AcaiComponentFour from '../../palmComponents/AcaiComponents/AcaiComponentFour'
import Footer from '../../palmComponents/Footer/Footer'
import AcaiComponentFive from '../../palmComponents/AcaiComponents/AcaiComponentFive'
import AcaiComponentSix from '../../palmComponents/AcaiComponents/AcaiComponentSix'
import AcaiComponentSeven from '../../palmComponents/AcaiComponents/AcaiComponentSeven'

const Acai = () => {
  return (
    <div className='w-full'>

      <Navbar />

      <AcaiComponentOne />

      <AcaiComponentTwo />

      <AcaiComponentThree />

      <AcaiComponentFour />

      <AcaiComponentFive />

      <AcaiComponentSix />

      <AcaiComponentSeven />

      <Footer />

    </div>
  )
}

export default Acai
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const FourthComponent = () => {

  const { activeLanguage } = useSelector(state => state.Languages)

  return (
    <div className='FourthComponent'>

      <div className='container'>

        <p className='mainHeading'>
          {
            activeLanguage === "Español" ?
              "Haga crecer su granja"
              :
              "Grow Your Farm"
          }
        </p>

        <p className='subHeading'>
          {
            activeLanguage === "Español" ?
              "Deja de permitir que el equipo limitado te frene. Con el servicio de alquiler de tractores de pago por uso de Holatractor, podrás acceder a la energía que necesitas para cultivar una granja próspera, temporada tras temporada."
              :
              "Stop letting limited equipment hold you back. With Holatractor's pay-as-you-go tractor rental service, you can access the power you need to cultivate a thriving farm, season after season."
          }
        </p>

      </div>

    </div>
  )
}

export default FourthComponent
import React, { useState } from "react";
import "./navbar.scss";
import Logo from "../../../utils/nnacres_white_v2.png";
import AccountHover from "./UserHover/AccountHover";
import ForBuilders from "./ForBuilders/ForBuilders";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ForComunidad from "./ForBuilders/ForComunidad";
import ForAgent from "./ForBuilders/ForAgent";
import ForInvestor from "./ForBuilders/ForInvestor";
import Login from "../../Login/Login";
import Cookie from "../../Cookie/Cookie";
import { useDispatch, useSelector } from 'react-redux';
import LanguageOptions from './LanguageOptions'
import { updateSelectLanguageComShowToFalse, updateSelectLanguageComShowToTrue } from "../../../redux/SelectLanguageComShow";

const Navbar = ({ bgTransparent }) => {
  const [accountHover, setAccountHover] = useState(false);
  const [builderHover, setBuilderHover] = useState(false);
  const [ownerHover, setOwnerHover] = useState(false);
  const [tenantHover, setTenantHover] = useState(false);
  const [insightsHover, setInsightsHover] = useState(false);
  const [menuHandler, setMenuHandler] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch()
  const activeLanguage = useSelector(state => state.Languages)
  const selectLanguageComShow = useSelector(state => state.SelectLanguageComShow)

  const OpenDialogBox = () => {
    setAccountHover(!false);
  };
  const CloseDialogBox = () => {
    setAccountHover(!true);
  };

  const OpenBuilderBox = () => {
    setBuilderHover(true);
  };
  const CloseBuilderBox = () => {
    setBuilderHover(false);
  };

  const OpenOwnerBox = () => {
    setOwnerHover(true);
  };
  const CloseOwnerBox = () => {
    setOwnerHover(false);
  };

  const OpenTenantBox = () => {
    setTenantHover(true);
  };
  const CloseTenantBox = () => {
    setTenantHover(false);
  };

  const OpenInsightBox = () => {
    setInsightsHover(true);
  };
  const CloseInsightBox = () => {
    setInsightsHover(false);
  };

  const OpenMenu = () => {
    setMenuHandler(!menuHandler);
  };

  return (
    <div
      className={`${location.pathname === "/"
        ? "navbar-container"
        : "navbar-container nav-bg"
        }`}
        style={{
          backgroundColor: bgTransparent ? "transparent" : "#AB0F0C",
        }}
    >
      <div className="navbar-section">
        <div className="nav-left">
          <p onClick={OpenMenu}>
            <i className="fa-solid fa-bars"></i>
          </p>

          <div className="logo">
            <img src={Logo} alt="Logo" onClick={() => navigate("/")} />
          </div>
        </div>
        <div className="nav-right">
          <ul className={`${menuHandler ? "ul" : "ul ul-list"}`}>
            <div className="nav-list">
              <li>
                <Link to="/farmer">
                {
                  activeLanguage.activeLanguage === "Español" ?
                    "Agricultores"
                    :
                    "Farmers"
                }
                </Link>
              </li>
            </div>
            <div
              className="nav-list"
              onMouseEnter={OpenTenantBox}
              onMouseLeave={CloseTenantBox}
            >
              <li>
                <a href="#">
                  {
                  activeLanguage.activeLanguage === "Español" ?
                    "Servicios"
                    :
                    "Services"
                }
                  </a>
              </li>
              <div
                className={`${tenantHover ? "nav-tenant" : "nav-tenant nav-hide"
                  }`}
              >
                <ForBuilders />
              </div>
            </div>
            <div
              className="nav-list"
              onMouseEnter={OpenOwnerBox}
              onMouseLeave={CloseOwnerBox}
            >
              <li>
                <a href="#">
                  {
                  activeLanguage.activeLanguage === "Español" ?
                    "Comunidad"
                    :
                    "Community"
                }
                  </a>
              </li>
              <div
                className={`${ownerHover ? "nav-owner" : "nav-owner nav-hide"}`}
              >
                <ForComunidad />
              </div>
            </div>
            <div
              className="nav-list"
              onMouseEnter={OpenBuilderBox}
              onMouseLeave={CloseBuilderBox}
            >
              <li>
                <a href="#">
                  {
                  activeLanguage.activeLanguage === "Español" ?
                    "Agente / Operadores"
                    :
                    "Agent/Operators"
                }
                  </a>
              </li>

              <div
                className={`${builderHover ? "nav-builder" : "nav-builder nav-hide"
                  }`}
              >
                <ForAgent />
              </div>
            </div>
            <div
              className="nav-list"
              onMouseEnter={OpenInsightBox}
              onMouseLeave={CloseInsightBox}
            >
              <li>
                <a href="#">
                  {
                  activeLanguage.activeLanguage === "Español" ?
                    "Inversores"
                    :
                    "Investors"
                }
                  </a>
              </li>
              <div
                className={`${insightsHover ? "nav-insight" : "nav-insight nav-hide"
                  }`}
              >
                <ForInvestor />
              </div>
            </div>
            <div className="nav-btn" onClick={() => { selectLanguageComShow ? dispatch(updateSelectLanguageComShowToFalse()) : dispatch(updateSelectLanguageComShowToTrue()) }}>
              <button name="language_selection_button">
                {
                  activeLanguage.activeLanguage === "Español" ?
                    "Seleccione el idioma"
                    :
                    "Select language"
                }
              </button>

              <div
                style={{
                  position: 'absolute'
                }}>
                {
                  selectLanguageComShow &&
                  <LanguageOptions />
                }
              </div>
            </div>
          </ul>
          {/* <div className="nav-btn">
            <button onClick={() => navigate("/search/123")}>Asocia tu aquí</button>
          </div> */}
          <div
            className="user-hover"
            onMouseEnter={OpenDialogBox}
            onMouseLeave={CloseDialogBox}
          >
            <div className="user-account">
              <p>
                <i className="fa-solid fa-user"></i>
              </p>
              <span>
                <i className="fa-solid fa-chevron-down"></i>
              </span>
            </div>
            <div className={`${accountHover ? "user-show" : "user-show hide"}`}>
              <AccountHover />
            </div>
          </div>
        </div>

      {/* <div
      className={menuHandler ? "resNavShow" : "resNavHide"}
      style={{
        position: 'absolute',
        top: '12vh',
        left: '0px',
        width: "300px",
        height: "86vh",
      }}>

      </div> */}
      </div>

      <Login />
      <Cookie />
    </div>
  );
};

export default Navbar;

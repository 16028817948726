import React from 'react'
import CenterIcon from './agriculturalCenter.png'
import TreeOne from './pottingSoil.png'
import TreeTwo from './root.png'
import TreeThree from './tractors.png'
import TreeFour from './commodity.png'
import TreeFive from './farm.png'
import CircularArrow from './CurcularArrow.png'
import { useSelector } from 'react-redux'

const PalmTreeProcess = () => {

    const languageOption = useSelector(state => state.language.language)

    return (
        <div className='w-full py-[40px]'>

            <div className='w-[90%] 1000px:w-[80%] mx-auto min-h-[90vh] max-h-fit relative'>

                {/* Center icon */}
                <div
                    className='m-auto w-fit h-fit absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] flex items-center justify-center flex-col hover:scale-110 transition-all duration-500'>

                    <img src={CenterIcon} alt="" className='w-[80px] 600px:w-[150px]' />
                    <p className='w-[80px] 600px:w-[150px] whitespace-normal text-center font-bold text-[20px] 600px:text-[26px]'>
                        {
                            languageOption === 'English' ?
                                'Regenerative agriculture'
                                :
                                'Agricultura regenerativa'
                        }
                    </p>

                </div>

                {/* tree1 icon */}
                <div
                    className='m-auto w-fit h-fit absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] mt-[10px] 600px:mt-[40px] ml-[120px] 600px:ml-[180px] flex items-center justify-center flex-col hover:scale-110 transition-all duration-500'>

                    <img src={TreeOne} alt="" className='w-[80px] 600px:w-[150px]' />
                    <p className='w-[80px] 600px:w-[150px] whitespace-normal text-center font-bold text-[20px] 600px:text-[26px]'>
                        {
                            languageOption === 'English' ?
                                'Covered soil'
                                :
                                'Suelo cubierto'
                        }
                    </p>

                </div>

                {/* Arrow */}
                <div className='m-auto w-fit h-fit absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] mt-[-90px] 600px:mt-[-100px] ml-[110px] 600px:ml-[180px]'>
                    <img src={CircularArrow} alt="" className='w-[40px] 600px:w-[60px] -rotate-[80deg]' />
                </div>

                {/* tree2 icon */}
                <div
                    className='m-auto w-fit h-fit absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] mt-[140px] 600px:mt-[220px] flex items-center justify-center flex-col hover:scale-110 transition-all duration-500'>

                    <img src={TreeTwo} alt="" className='w-[80px] 600px:w-[150px]' />
                    <p className='w-[80px] 600px:w-[150px] whitespace-normal text-center font-bold text-[20px] 600px:text-[26px]'>
                        {
                            languageOption === 'English' ?
                                'Maintain living root'
                                :
                                'Mantener la raíz viva'
                        }
                    </p>

                </div>

                {/* Arrow */}
                <div className='m-auto w-fit h-fit absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] mt-[110px] 600px:mt-[180px] ml-[80px] 600px:ml-[160px]'>
                    <img src={CircularArrow} alt="" className='w-[40px] 600px:w-[60px] -rotate-[20deg]' />
                </div>

                {/* tree3 icon */}
                <div
                    className='m-auto w-fit h-fit absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] mt-[10px] 600px:mt-[40px] ml-[-110px] 600px:ml-[-180px] flex items-center justify-center flex-col hover:scale-110 transition-all duration-500'>

                    <img src={TreeThree} alt="" className='w-[80px] 600px:w-[150px]' />
                    <p className='w-[80px] 600px:w-[150px] whitespace-normal text-center font-bold text-[20px] 600px:text-[26px]'>
                        {
                            languageOption === 'English' ?
                                'Minimize disturbance'
                                :
                                'Minimizar las molestias'
                        }

                    </p>

                </div>

                {/* Arrow */}
                <div className='m-auto w-fit h-fit absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] mt-[110px] 600px:mt-[180px] ml-[-80px] 600px:ml-[-160px]'>
                    <img src={CircularArrow} alt="" className='w-[40px] 600px:w-[60px] rotate-[70deg]' />
                </div>

                {/* tree4 icon */}
                <div
                    className='m-auto w-fit h-fit absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] mt-[-130px] 600px:mt-[-200px] ml-[-70px] 600px:ml-[-120px] flex items-center justify-center flex-col hover:scale-110 transition-all duration-500'>

                    <img src={TreeFour} alt="" className='w-[80px] 600px:w-[150px]' />
                    <p className='w-[80px] 600px:w-[150px] whitespace-normal text-center font-bold text-[20px] 600px:text-[26px]'>
                        {
                            languageOption === 'English' ?
                                'Crop diversity'
                                :
                                'Diversidad de cultivos'
                        }

                    </p>

                </div>

                {/* Arrow */}
                <div className='m-auto w-fit h-fit absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] mt-[-80px] 600px:mt-[-100px] ml-[-120px] 600px:ml-[-200px]'>
                    <img src={CircularArrow} alt="" className='w-[40px] 600px:w-[60px] rotate-[150deg]' />
                </div>

                {/* tree5 icon */}
                <div
                    className='m-auto w-fit h-fit absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] mt-[-130px] 600px:mt-[-200px] ml-[50px] 600px:ml-[100px] flex items-center justify-center flex-col hover:scale-110 transition-all duration-500'>

                    <img src={TreeFive} alt="" className='w-[80px] 600px:w-[150px]' />
                    <p className='w-[80px] 600px:w-[150px] whitespace-normal text-center font-bold text-[20px] 600px:text-[26px]'>
                        {
                            languageOption === 'English' ?
                                'livestock'
                                :
                                'ganado'
                        }

                    </p>

                </div>

                {/* Arrow */}
                <div className='m-auto w-fit h-fit absolute left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] mt-[-140px] 600px:mt-[-200px]'>
                    <img src={CircularArrow} alt="" className='w-[40px] 600px:w-[60px] rotate-[210deg]' />
                </div>

            </div>

        </div>
    )
}

export default PalmTreeProcess
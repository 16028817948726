import React from "react";
import "./creativity.scss";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import FlipCameraAndroidOutlinedIcon from "@mui/icons-material/FlipCameraAndroidOutlined";
import CreativityImg from "./../../assets/g3.jpeg";
import { useSelector } from 'react-redux'

const Creativity = () => {

  const { activeLanguage } = useSelector(state => state.Languages)

  const bottomIconStyle = {
    padding: "10px",
    width: "45px",
    height: "45px",
    borderRadius: "5px",
    backgroundColor: "#d0e8fd",
    color: "#469af5",
    cursor: "pointer",
  };

  return (
    <div className="creativity-section">
      {
        activeLanguage === "Español" ?
          <h2>
            ¿Cómo podemos ayudar con<span> HolaTractor!</span>
          </h2>
          :
          <h2>
            How can we help with<span> HolaTractor!</span>
          </h2>
      }
      <div className="creativity-container">
        <div className="creativity-left">
          <div className="d-flex">
            <StarBorderOutlinedIcon style={bottomIconStyle} />
            {
              activeLanguage === "Español" ?
                <div className="text">
                  <h3>Servicios de tractores</h3>
                  <p>
                    Brindamos el mejor servicio y transporte de tractores en toda el área tri-estatal. Nuestro servicio óptimo está a su conveniencia, con un desempeño rápido a un precio razonable.
                  </p>
                </div>
                :
                <div className="text">
                  <h3>Tractor services</h3>
                  <p>
                    We provide the best tractor service and transportation throughout the tri-state area. Our optimal service is at your convenience, with fast performance at a reasonable price.
                  </p>
                </div>
            }
          </div>

          <div className="d-flex">
            <ThumbUpOutlinedIcon style={bottomIconStyle} />
            {
              activeLanguage === "Español" ?
                <div className="text">
                  <h3>Asistencia financiera para propietarios de tractores</h3>
                  <p>
                    Si desea comprar un tractor, lo financiaremos por usted y lo ayudaremos a ganar dinero a través de nuestros servicios de tractores.
                  </p>
                </div>
                :
                <div className="text">
                  <h3>Financial assistance for tractor owners</h3>
                  <p>
                    If you want to buy a tractor, we will finance it for you and help you make money through our tractor services.
                  </p>
                </div>
            }
          </div>

          <div className="d-flex">
            <SearchOutlinedIcon style={bottomIconStyle} />
            {
              activeLanguage === "Español" ?
                <div className="text">
                  <h3>Oportunidades de afiliación para nuestros socios</h3>
                  <p>
                    Hacemos alianzas con personalidades de liderazgo que administran grupos comunitarios a través de nuestra plataforma inteligente. Y ofrecemos una buena oportunidad de ganar.
                  </p>
                </div>
                :
                <div className="text">
                  <h3>Membership opportunities for our members</h3>
                  <p>
                    We partner with leadership personalities who manage community groups through our intelligent platform. And we offer a good chance to win.
                  </p>
                </div>
            }
          </div>

          <div className="d-flex">
            <FlipCameraAndroidOutlinedIcon style={bottomIconStyle} />
            {
              activeLanguage === "Español" ?
                <div className="text">
                  <h3>Grupo de la comunidad!</h3>
                  <p>
                    Nuestra comunidad , es un grupo de personas que viven cercanos , unos de otros, y comparten intereses comunes, como la cultura o la historia. En muchos casos, las comunidades agrícolas son estrechas, y se apoyan mutuamente, lo que puede ser muy beneficioso cuando se trata de productividad agrícola o iniciar un nuevo negocio.
                  </p>
                </div>
                :
                <div className="text">
                  <h3>Community group!</h3>
                  <p>
                    Our community is a group of people who live close to each other and share common interests, such as culture or history. In many cases, farming communities are close, and mutually supportive, which can be very beneficial when it comes to agricultural productivity or starting a new business.
                  </p>
                </div>
            }
          </div>
        </div>
        <div className="creativity-right">
          <img src={CreativityImg} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Creativity;

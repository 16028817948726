import React from 'react'
import serviceBackgroundVideo from '../../../../../assets/video/service.mp4'
import { useSelector } from 'react-redux'

const Banner = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className="Banner">

            <video autoPlay loop muted>
                <source src={serviceBackgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <h1>
                {
                    activeLanguage === "Español" ?
                        "Tractores agrícolas para cada campo"
                        :
                        "Agricultural Tractors for Every Farm"
                }
            </h1>

            {
                activeLanguage === "Español" ?
                    <p className='paragraphOne'>
                        Los tractores agrícolas son los caballos de batalla de la industria agrícola. Estas poderosas máquinas desempeñan un papel vital en cada etapa del ciclo de producción de alimentos, desde la preparación de la tierra y la siembra hasta la cosecha y el transporte.
                    </p>
                    :
                    <p className='paragraphOne'>
                        Agricultural tractors are the workhorses of the farming industry. These powerful machines play a vital role in every stage of the food production cycle, from land preparation and planting to harvesting and transportation.
                    </p>
            }

        </div>
    )
}

export default Banner
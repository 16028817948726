import React from 'react'
import { useSelector } from 'react-redux'

const PalmRecollectionTwo = () => {

    const languageOption = useSelector(state => state.language.language)

  return (
    <div className='w-[90%] 1000px:w-[80%] mx-auto py-[40px]'>
        {
                    languageOption === 'English' ?
                        <div
                            className='font-bold text-[18px] 500px:text-[24px] 1100px:text-[27px] 1400px:text-[28px] 1500px:text-[30px] text-[#4D4D4D] w-[90%] 600px:w-[75%] relative before:absolute before:left-[-30px] before:h-full before:w-0 600px:before:w-[3px] before:rounded-full before:bg-[#00A651] my-[30px] mx-auto'>

                            <span className='text-[#00A651]'>Cover Cropping:</span> Medium-sized tractors with adequate horsepower are commonly used for cover cropping. They need to be versatile for various attachments.Tractor attachments include no-till drills, broadcast seeders, and precision planters, which help plant cover crop seeds efficiently.


                        </div>
                        :
                        <div
                            className='font-bold text-[18px] 500px:text-[24px] 1100px:text-[27px] 1400px:text-[28px] 1500px:text-[30px] text-[#4D4D4D] w-[90%] 600px:w-[75%] relative before:absolute before:left-[-30px] before:h-full before:w-0 600px:before:w-[3px] before:rounded-full before:bg-[#00A651] my-[30px] mx-auto'>

                            <span className='text-[#00A651]'>Cultivos de cobertura:</span> Los tractores de tamaño mediano con potencia adecuada se utilizan comúnmente para cultivos de cobertura. Deben ser versátiles para varios accesorios. Los accesorios para tractores incluyen sembradoras sin labranza, sembradoras al voleo y sembradoras de precisión, que ayudan a plantar semillas de cultivos de cobertura de manera eficiente.


                        </div>
                }
    </div>
  )
}

export default PalmRecollectionTwo
import React from 'react'
import { useSelector } from 'react-redux'

const SeventhComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentArray = activeLanguage === "Español" ? [
        {
            heading: "Tomar el control de sus operaciones",
            description: "Realice tareas que antes se subcontrataban o se realizaban manualmente, ahorrando tiempo y dinero.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/market_disturption.png"
        },
        {
            heading: "Aumentar la productividad",
            description: "Haga más en menos tiempo con la potencia y versatilidad de un tractor compacto.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/cutomers_expect.png"
        },
        {
            heading: "Ampliar posibilidades",
            description: "Afronta nuevos proyectos y explora nuevas técnicas agrícolas con el equipo adecuado a tu disposición.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/operational.png"
        },
        {
            heading: "hacer crecer su sueño",
            description: "Invierta en su futuro con una herramienta poderosa y eficiente que puede ayudar a que su pequeña granja prospere.",
            imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/data_decission.png"
        },
    ]
        :
        [
            {
                heading: "Take control of their operations",
                description: "Perform tasks that were previously outsourced or done manually, saving time and money.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/market_disturption.png"
            },
            {
                heading: "Increase productivity",
                description: "Get more done in less time with the power and versatility of a compact tractor.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/cutomers_expect.png"
            },
            {
                heading: "Expand possibilities",
                description: "Tackle new projects and explore new farming techniques with the right equipment at your disposal.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/operational.png"
            },
            {
                heading: "Grow their dream",
                description: "Invest in their future with a powerful and efficient tool that can help their small farm flourish.",
                imageLink: "https://websin.s3.ap-south-1.amazonaws.com/services/icons/data_decission.png"
            },
        ]

    return (
        <div
            className='SeventhComponent'>

            <div
                className='seventh_container'
                style={{
                    // background: 'radial-gradient(50% 50% at 50% 50%, rgba(158, 110, 230, 0.28) 0%, rgba(158, 110, 230, 0) 100%)'
                }}>

                <h1
                    className='text-3xl 1000px:text-5xl'>
                    {
                        activeLanguage === "Español" ?
                            "Poseer un tractor compacto empodera a los pequeños agricultores."
                            :
                            "Owning a compact tractor empowers small farmers"
                    }
                </h1>

                <div
                    className='grid-cols-1 800px:grid-cols-2 data_container'>

                    {
                        componentArray.map((details, index) => {
                            return (
                                <div
                                    key={index}
                                    className='data_box'>

                                    <img
                                        alt={details.heading}
                                        src={details.imageLink}
                                        style={{
                                            width: '40px',
                                            height: '40px',
                                            objectFit: 'cover'
                                        }} />

                                    <div
                                        className='text-lg 1000px:text-2xl data_text_container'>

                                        <p>
                                            <b>
                                                {details.heading}
                                            </b>
                                        </p>

                                        <p>
                                            {details.description}
                                        </p>

                                    </div>

                                </div>
                            )
                        })
                    }

                </div>

            </div>

        </div>
    )
}

export default SeventhComponent
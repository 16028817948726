import React from 'react'
import { useSelector } from 'react-redux'

const ConnectSection = () => {
  const { activeLanguage } = useSelector(state => state.Languages)
  return (
    <div className='connect'>

{
  activeLanguage === "Español" ?
  <h1>
      Solicitud de reserva de tractor
      </h1>
      :
      <h1>
      Tractor reservation request
      </h1>
      }

      <hr />

{
  activeLanguage === "Español" ?
  <h3>
      Tu campo, tus necesidades, nuestros tractores
      </h3>
      :
      <h3>
      Your field, your needs, our tractors
      </h3>
      }

      <div className="inputs">
        <input
          className="name"
          id="first_name"
          name="first_name"
          placeholder={activeLanguage === "Español" ? "Nombre de pila" : "First name"}
          type='text' />
        <input
          className="name"
          id="last_name"
          name="last_name"
          placeholder={activeLanguage === "Español" ? "Apellido" : "Last name"}
          type='text' />
      </div>

      <div className="inputs">
        <input
          className="name"
          id="email"
          name="email"
          placeholder={activeLanguage === "Español" ? 'Correo electrónico' : "Email" }
          type='email' />
        <input
          className="name"
          id="phone_number"
          name="phone_number"
          placeholder={activeLanguage === "Español" ?'Número de teléfono' : "Phone number" }
          type='text' />
      </div>

      <div className="inputs">
          <select name="tractor_type" id="tractor_type">
            <option defaultChecked>{activeLanguage === "Español" ? "Tipo de tractor" : "Tractor type"}</option>
            <option value={"small"}>{activeLanguage === "Español" ? "Pequeño" : "Small"}</option>
            <option value={"medium"}>{activeLanguage === "Español" ? "Mediano" : "Medium"}</option>
            <option value={"large"}>{activeLanguage === "Español" ? "Grande" : "Large"}</option>
          </select>
          <select name="tractor_type" id="tractor_type">
            <option defaultChecked>{activeLanguage === "Español" ? "Presupuesto" : "Budget"}</option>
            <option value={""}>0$ -500$</option>
            <option value={""}>500$ - 1000$</option>
            <option value={""}>1000$ - 1500$</option>
            <option value={""}>1500$ - 2000$</option>
            <option value={""}>2000$ - 2500$</option>
            <option value={""}>2500$ - 3000$</option>
          </select>
      </div>

      <textarea
        id="message"
        name="message"
        className="message"
        placeholder={activeLanguage === "Español" ? 'Cuéntanos más sobre' : "Tell us more about"} />

      <button
        name='send now button'>
        {activeLanguage === "Español" ? "Enviar" : "Send"}
      </button>

    </div>
  )
}

export default ConnectSection
const VisitBox = ({ headingOne, headingTwo, icon }) => {
    return(
        <div className="visitBox">

            <img
            alt="icon"
            src={icon} />

            <div className="right">

                <p className="headingOne">
                    {headingOne}
                </p>

                <p className="headingTwo">
                    {headingTwo}
                </p>

            </div>

        </div>
    )
}

export default VisitBox
import React from 'react'
import { useSelector } from 'react-redux'

const ThirdComponent = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className='ThirdComponent'>

            <div className='container'>

                <p className='subHeading'>
                    {
                        activeLanguage === "Español" ?
                            "En Holatractor ofrecemos una amplia selección de tractores compactos nuevos y usados ​​de varias marcas reconocidas. Nuestro agente puede ayudarle a encontrar el tractor perfecto que se adapte a sus necesidades y presupuesto. También ofrecemos una variedad de accesorios para ampliar las capacidades de su tractor."
                            :
                            "At Holatractor, we offer a wide selection of new and pre-owned compact tractors from various reputable brands. Our Agent can help you find the perfect tractor to meet your needs and budget. We also offer a variety of attachments to expand your tractor's capabilities."
                    }
                </p>

            </div>

        </div>
    )
}

export default ThirdComponent
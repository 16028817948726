import React from 'react'
import { useSelector } from 'react-redux'

const Benefits = () => {
  const { activeLanguage } = useSelector(state => state.Languages)
  return (
    <div className='Benefits'>

      {
        activeLanguage === "Español" ?
          <div className="left">
            <div className="heading">Holatractor para agricultores Modelo de reparto</div>
            <div className='details-container'>
              <img src="" alt="" />
              <span className='span'>Ofrecemos tarifas competitivas para los servicios de reserva de tractores, haciéndolos accesibles a pequeños agricultores con presupuestos limitados.</span>
            </div>
            <div className='details-container'>
              <img src="" alt="" />
              <span className='span'>Nuestra plataforma fácil de usar facilita la reserva de tractores y la gestión de alquileres, ahorrándole tiempo y molestias.</span>
            </div>
            <div className='details-container'>
              <img src="" alt="" />
              <span className='span'>Nuestro equipo está aquí para ayudarle en cada paso del camino, desde reservar su tractor hasta resolver cualquier problema que pueda surgir.</span>
            </div>
          </div>
          :
          <div className="left">
            <div className="heading">Holatractor for farmers Delivery model</div>
            <div className='details-container'>
              <img src="" alt="" />
              <span className='span'>We offer competitive rates for tractor booking services, making them accessible to small farmers with limited budgets.</span>
            </div>
            <div className='details-container'>
              <img src="" alt="" />
              <span className='span'>Our easy-to-use platform makes tractor booking and rental management easy, saving you time and hassle.</span>
            </div>
            <div className='details-container'>
              <img src="" alt="" />
              <span className='span'>Our team is here to help you every step of the way, from reserving your tractor to resolving any issues that may arise.</span>
            </div>
          </div>
      }
      <div className="right">
        <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/traactor_book.svg" alt="" />
      </div>
    </div>
  )
}

export default Benefits
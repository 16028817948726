import React from 'react'
import NewBannerImage from '../../../assets/new_banner.png'
import MiddleBtn from '../../../components/Home/HomeMiddle/MiddleButton/MiddleBtn'
import { useSelector } from 'react-redux'

const Banner = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentArray = activeLanguage === "Español" ? [
        {
            heading: "Tractor",
        },
        {
            heading: "Dispositivo IOT",
        },
        {
            heading: "Software",
        },
    ] : [
        {
            heading: "Tractor",
        },
        {
            heading: "IOT device",
        },
        {
            heading: "Software",
        },
    ]

    return (
        <div
            className='Banner'>

            <img
                alt='New banner image'
                src={NewBannerImage}
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    left: '0px',
                    top: '0px',
                    objectFit: 'cover',
                    zIndex: '-1'
                }} />

            <div
                className='banner_container'>

                <div
                    className='banner_left_container'>

                    {
                        activeLanguage === "Español" ?
                            <div
                                className='text-container'
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px'
                                }}>

                                <h1>
                                    Mecanización agrícola
                                </h1>

                                <h4>
                                    La mecanización de los tractores está revolucionando la agricultura. Al asociarse con holatractor para acceder a la última tecnología en tractores, podrá explorar un nuevo nivel de eficiencia, productividad y rentabilidad para su granja.
                                </h4>

                            </div>
                            :
                            <div
                                className='text-container'
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px'
                                }}>

                                <h1>
                                    Agriculture Mechanizatio
                                </h1>

                                <h4>
                                    Tractor mechanization is revolutionizing agriculture. By partnering with holatractor to access the latest tractor technology, you can explore a new level of efficiency, productivity, and profitability for your farm.
                                </h4>

                            </div>
                    }

                    <div>

                        <MiddleBtn />

                    </div>

                </div>

                <div
                    className='banner_right_container'>

                    <div
                        className='text_container'>

                        {
                            componentArray.map((details, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='map_container'>

                                        <h2>
                                            {details.heading}
                                        </h2>

                                    </div>
                                )
                            })
                        }

                    </div>

                    <img
                        alt='mobile development banner'
                        src={"https://websin.s3.ap-south-1.amazonaws.com/services/mobile_development.webp"} />

                </div>

            </div>

        </div>
    )
}

export default Banner
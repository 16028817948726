import React, { useEffect, useState } from 'react'
import AcUnitIcon from '@mui/icons-material/AcUnit';
import { useSelector } from 'react-redux'

const FirstComponent = () => {

    const [activeArrayElement, setActiveArrayElement] = useState(0)

    const { activeLanguage } = useSelector(state => state.Languages)

    const componentArray = activeLanguage === "Español" ? [
        {
            mainHeading: "Maniobrabilidad",
            subHeading: "Los tractores compactos destacan en espacios reducidos. Su tamaño más pequeño permite navegar en hileras estrechas, maniobrar alrededor de obstáculos y realizar tareas en áreas reducidas que los tractores más grandes simplemente no pueden alcanzar."
        },
        {
            mainHeading: "Versatilidad",
            subHeading: "¡No te dejes engañar por su tamaño compacto! Los tractores compactos pueden realizar una variedad de trabajos con la ayuda de accesorios. Desde cortar pastos y cultivar jardines hasta cargar materiales y limpiar maleza, ofrecen una versatilidad impresionante."
        },
        {
            mainHeading: "Económico",
            subHeading: "En comparación con los tractores más grandes, los tractores compactos son más asequibles de comprar y mantener. A menudo requieren menos combustible y tienen costos operativos más bajos, lo que los convierte en una opción económica para muchos usuarios."
        },
        {
            mainHeading: "Facilidad de uso",
            subHeading: "Los tractores compactos suelen ser fáciles de usar, incluso para quienes tienen experiencia limitada. Sus controles suelen ser sencillos y su tamaño más pequeño los hace más fáciles de manejar y maniobrar."
        },

    ]
        :
        [
            {
                mainHeading: "Maneuverability",
                subHeading: "Compact tractors excel in tight spaces. Their smaller size allows for navigating narrow rows, maneuvering around obstacles, and tackling tasks in confined areas that larger tractors simply can't reach."
            },
            {
                mainHeading: "Versatility",
                subHeading: "Don't be fooled by their compact size! Compact tractors can handle a variety of jobs with the help of attachments. From mowing pastures and tilling gardens to loading materials and clearing brush, they offer impressive versatility."
            },
            {
                mainHeading: "Cost-Effective",
                subHeading: "Compared to larger tractors, compact tractors are more affordable to purchase and maintain. They often require less fuel and have lower operating costs, making them a budget-friendly option for many users."
            },
            {
                mainHeading: "Ease of Use",
                subHeading: "Compact tractors are generally user-friendly, even for those with limited experience. Their controls are typically straightforward, and their smaller size makes them easier to handle and maneuver."
            },

        ]

    function changeContext() {
        setActiveArrayElement(prev => prev + 1 === componentArray.length ? 0 : prev + 1)
    }

    useEffect(() => {
        const intervalSet = setInterval(() => {
            changeContext()
        }, 3000)
        return () => clearInterval(intervalSet);
    }, [])

    return (
        <div className='FirstComponent'>

            <div className="container">

                <div className="left-container">

                    <p className='mainHeading'>
                        {
                            activeLanguage === "Español" ?
                                "¿Por qué elegir un tractor compacto?"
                                :
                                "Why Choose a Compact Tractor?"
                        }
                    </p>

                    <div className="paragraphsContainer">

                        {
                            componentArray.map((details, index) => {
                                return (
                                    <div className='box' key={index}>

                                        <div className='box-top'>
                                            <AcUnitIcon />
                                            <p>
                                                {details.mainHeading}
                                            </p>
                                        </div>

                                        <div className="box-middle">
                                            <div
                                                className="inside-box-middle"
                                                style={{
                                                    display: activeArrayElement === index ? 'block' : 'none'
                                                }} />
                                        </div>

                                        <div className="box-bottom" style={{
                                            display: activeArrayElement === index ? 'block' : 'none'
                                        }}>
                                            <p>
                                                {details.subHeading}
                                            </p>
                                        </div>

                                    </div>
                                )
                            })
                        }

                    </div>

                </div>

                <div className="right-container">
                    <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/tractor/tractor_two.webp" alt="Third component" />
                </div>

            </div>

        </div>
    )
}

export default FirstComponent
import React from 'react'

const FirstComponent = () => {
    const gridSectionArray = [
        {
            heading: "Fast loan approvals",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, laborum!"
        },
        {
            heading: "Fast loan approvals",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, laborum!"
        },
        {
            heading: "Fast loan approvals",
            description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, laborum!"
        },
    ]

    return (
        <div className='FirstComponent'>

            <div className="gridSection">

                {
                    gridSectionArray.map((details, index) => {
                        return (
                            <div className="gridBox" key={index}>

                            <h3>{details.heading}</h3>

                            <p>{details.description}</p>

                            </div>
                        )
                    })
                }

            </div>

            <div className="belowGridSection">

                <img 
                src="https://www.xpressloan.in/assets/img/about-banner.jpg" 
                alt="" />

                <div className="belowGridSectionRight">

                    <h2>
                    Any time you are in need of instant funds you will always find us there!
                    </h2>

                    <p>
                    XpressLoan is an online platform which you can approach confidently whenever you find yourself without cash at any time during the month. It can be daunting to have no money in the household with salary date still far away. This is exactly where we come in. Our instant loan is simple to acquire. With minimal documentation, the entire process right from the registration until the disbursal is smooth and hassle-free to provide you with the best end-to-end experience.
                    </p>

                    <button
                    name="Read more button">
                        Read more
                    </button>

                </div>

            </div>

        </div>
    )
}

export default FirstComponent
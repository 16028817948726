import React from 'react'
import Navbar from '../../palmComponents/Navbar/Navbar'
import Footer from '../../palmComponents/Footer/Footer'
import CommunityComponentOne from '../../palmComponents/CommunityComponents/CommunityComponentOne'
import CommunityComponentTwo from '../../palmComponents/CommunityComponents/CommunityComponentTwo'
import CommunityComponentThree from '../../palmComponents/CommunityComponents/CommunityComponentThree'
import CommunityComponentFour from '../../palmComponents/CommunityComponents/CommunityComponentFour'

const Community = () => {
  return (
    <div className="w-full relative">
      <div className="absolute h-full w-[1px] z-50 bg-yellow-200 left-[10%] top-0 hidden 900px:block" />
      <div className="absolute h-full w-[1px] z-50 bg-yellow-200 left-[50%] top-0 hidden 900px:block" />
      <div className="absolute h-full w-[1px] z-50 bg-yellow-200 right-[10%] top-0 hidden 900px:block" />
      <Navbar />
      <CommunityComponentOne />
      <CommunityComponentTwo />
      <CommunityComponentThree />
      <CommunityComponentFour />
      <Footer />
    </div>
  )
}

export default Community
import React from 'react'
import serviceBackgroundVideo from '../../../../../assets/video/service.mp4'
import { useSelector } from 'react-redux'

const Banner = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className="Banner">

            <video autoPlay loop muted>
                <source src={serviceBackgroundVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <h1>
                {
                    activeLanguage === "Español" ?
                        "¡Bienvenido a Holatractor Alquiler de Tractores!"
                        :
                        "Welcome to Holatractor Tractor Rentals!"
                }
            </h1>

            {
                activeLanguage === "Español" ?
                    <p className='paragraphOne'>
                        Ofrecemos un servicio de reserva y alquiler de tractores cómodo y rentable, perfecto para una variedad de necesidades.
                    </p>
                    :
                    <p className='paragraphOne'>
                        We offer a convenient and cost-effective tractor booking and rental service, perfect for a variety of needs.
                    </p>
            }

        </div>
    )
}

export default Banner
import { createSlice } from '@reduxjs/toolkit'

export const ContactPageShow = createSlice({
    name:"ContactPageShow",
    initialState: {
        show: false
    },
    reducers:{
        updateContactPageShow: (state,action)=>{
            state.show = !state.show
            console.log(state.show)
        }
    }
})

export const { updateContactPageShow } = ContactPageShow.actions;
export default ContactPageShow.reducer
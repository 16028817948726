import React from 'react'
import EastIcon from '@mui/icons-material/East';
import { useSelector } from 'react-redux';

const Technology = () => {
    const { activeLanguage } = useSelector(state => state.Languages)
    return (
        <div className='about'>

            {
                activeLanguage === "Español" ?
                    <div className="container">

                        <div className="left">
                            <h2>
                                TECNOLOGÍA
                            </h2>
                            <div className="subHeading">
                                Con HolaTractor
                                <div className="arrowIcon">
                                    <EastIcon />
                                </div>
                            </div>
                        </div>

                        <div className="right">
                            <p>
                                Nuestros servicios de reserva de tractores están impulsados ​​por una pila de tecnología sólida e innovadora, diseñada para agilizar las operaciones, mejorar la experiencia del usuario e impulsar la eficiencia.
                            </p>
                        </div>

                    </div>
                    :
                    <div className="container">

                        <div className="left">
                            <h2>
                                TECHNOLOGY
                            </h2>
                            <div className="subHeading">
                                With Holla Tractor
                                <div className="arrowIcon">
                                    <EastIcon />
                                </div>
                            </div>
                        </div>

                        <div className="right">
                            <p>
                                Our tractor booking services are powered by a robust and innovative technology stack, designed to streamline operations, improve user experience and drive efficiency.
                            </p>
                        </div>

                    </div>
            }

            <div className="container">

                {
                    activeLanguage === "Español" ?
                    <div className="right">
                    <div className="headingContainer">
                        <h2>
                            TECNOLOGÍA
                        </h2>
                        <div className="subHeading">
                            HOLATRACTOR PLATAFORMA
                        </div>
                    </div>
                    <p>
                        Los dispositivos habilitados para IoT en los tractores permiten un seguimiento preciso de su ubicación y estado, lo que garantiza una entrega oportuna y una utilización eficiente.Nuestras aplicaciones móviles brindan una forma conveniente e intuitiva para que los agricultores y propietarios de tractores reserven, rastreen y administren sus tractores.Los agricultores pueden recibir actualizaciones en tiempo real sobre la ubicación y el estado de los tractores, lo que garantiza una entrega oportuna y operaciones eficientes.Nos comunicamos con nuestros clientes a través de Sass Framework, aplicación de reservas, aplicación de operador/propietario y aplicación de agente.
                    </p>
                    <button
                        name='build your world button'>
                        CONTÁCTENOS
                    </button>
                </div>
                :
                <div className="right">
                    <div className="headingContainer">
                        <h2>
                        TECHNOLOGY
                        </h2>
                        <div className="subHeading">
                            HOLATRACTOR PLATFORM
                        </div>
                    </div>
                    <p>
                    IoT enabled devices on tractors enable accurate tracking of their location and status, ensuring timely delivery and efficient utilization. Our mobile apps provide a convenient and intuitive way for farmers and tractor owners to book, track and manage their tractors.Farmers can receive real-time updates on the location and status of tractors, ensuring timely delivery and efficient operations.We communicate with our customers through Sass Framework, Booking App, Operator App /owner and agent application.
                    </p>
                    <button
                        name='build your world button'>
                        CONTACT US
                    </button>
                </div>
                }

                <div className="left">
                    <img
                        alt="about image"
                        src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/booking/dashboard.webp" />
                </div>

            </div>

        </div>
    )
}

export default Technology
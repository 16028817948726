import React from 'react'
import { useSelector } from 'react-redux'

const ServiceFooter = () => {

    const { activeLanguage } = useSelector(state => state.Languages)

    return (
        <div className='ServiceFooter'>

            <div className="left">

                <img src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/services/tractor_booking.webp" alt="" />

            </div>


            {
                activeLanguage === "Español" ?
                    <div className="right">

                        <div className="headingOne">
                        Su solución integral para servicios de tractores
                        </div>

                        <div className="subHeading">
                        Experimente la velocidad y conveniencia de los servicios de reserva de tractores de HolaTractor. Ofrecemos una amplia gama de servicios para satisfacer las diversas necesidades tanto de agricultores como de empresas comerciales.
                        </div>

                        <div className="button">
                        Habla con un experto
                        </div>

                    </div>
                    :
                    <div className="right">

                        <div className="headingOne">
                        Your One-Stop Solution for Tractor Services
                        </div>

                        <div className="subHeading">
                        Experience the speed and convenience of HolaTractor's tractor booking services. We offer a wide range of services to meet the diverse needs of both farmers and commercial enterprises.
                        </div>

                        <div className="button">
                            Talk to an expert
                        </div>

                    </div>
            }

        </div>
    )
}

export default ServiceFooter
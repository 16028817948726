import React from 'react'

const Banner = () => {
    return (
        <div className='Banner'>

            <div className="container">

                <div className="details-box">

                    <div className="left-details">

                        <h1>
                            Lorem ipsum dolor sit amet.
                        </h1>

                        <div className="left-paragraph">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident fugiat, mollitia, accusantium reiciendis dolor id corporis quam incidunt, magnam nisi eligendi placeat adipisci neque sequi in quisquam voluptatum numquam! Rem, temporibus quisquam. Nam perferendis doloribus, unde facilis rerum quo laboriosam corrupti numquam incidunt, repellendus eaque quasi consequatur? Delectus, cum voluptatum.
                        </div>

                        {/* <Link to={'#'}> */}
                        <button
                            name='know more button'>
                            lorem
                        </button>
                        {/* </Link> */}

                    </div>

                    <div
                        className="right-details">
                        <img
                            src="https://wallpapercave.com/wp/wp13121058.jpg"
                            alt="mini tractor" />
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Banner
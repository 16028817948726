import React, { useEffect, useState, useRef } from "react";
import "./searchbar.scss";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import KeyboardDoubleArrowUpOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { useLocation, useNavigate } from "react-router-dom";

import { format } from "date-fns";
import { enGB } from "date-fns/locale";
import { DateRangePickerCalendar, START_DATE } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import axios from "axios";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import AutoComplete from "react-google-autocomplete";
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { useSelector } from "react-redux";


const SearchBar = () => {
  const [calenderShowHide, setCalenderShowHide] = useState(false);
  const [menuShowHide, setMenuShowHide] = useState(false);
  const [locationShowHide, setLocationShowHide] = useState(false);
  const [calShowHide, setCalShowHide] = useState(false);
  const [dateOne, setDateOne] = useState();
  const [dateTwo, setDateTwo] = useState();
  const [activeTractorFilter, setActiveTractorFilter] = useState(0)

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [focus, setFocus] = useState(START_DATE);
  const handleFocusChange = (newFocus) => {
    setFocus(newFocus || START_DATE);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const [ip, setIp] = useState('');
  const [city, setCity] = useState('')
  const [lat, setLat] = useState()
  const [lang, setLang] = useState()

  const topTractorPlacesScroll = useRef(null)

  const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyBW4zTbHr_IOn2vRs-n-I7L0XVQSU7j4Rc"

  // const GetDate = () => {
  // const { pickedDates } = useDatePickGetter();
  // const resetFunc = useDatePickReset();

  // const saveDate = () => {
  //   setDateOne(pickedDates.firstPickedDate?.toLocaleDateString());
  //   setDateTwo(pickedDates.secondPickedDate?.toLocaleDateString());
  //   setCalenderShowHide(false);
  // };

  //   return (
  //     <div className="date-container">
  //       <DatePicker disablePreviousDays />
  //       {/* <button type="button" onClick={resetFunc}>
  //         Clear
  //       </button> */}
  //       <button type="button" onClick={saveDate}>
  //         Save
  //       </button>
  //     </div>
  //   );
  // };

  const activeLanguage = useSelector(state => state.Languages)

  const iconStyle = {
    padding: "9px",
    width: "40px",
    height: "40px",
    borderRadius: "999px",
    backgroundColor: "#f0f9ff",
    cursor: "pointer",
  };

  useEffect(() => {
    axios.get("https://api64.ipify.org?format=json")
      .then((res) => {
        // console.log(res)
        // const userIp = response.data.ip;
        setIp(res.data.ip);
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (ip) {
      axios.get(`https://ipapi.co/${ip}/json/`)
        .then((res) => {
          // console.log(res)
          const { city, latitude, longitude } = res.data
          // console.log(city)
          setCity(city)
          setLat(latitude)
          setLang(longitude)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [ip])

  const tractorFilterContainer =
    activeLanguage.activeLanguage === "Español" ?
      [
        "Tractor pequeño",
        "Tractor mediano",
        "Tractor grande"
      ]
      :
      [
        "Small tractor",
        "Medium tractor",
        "Large tractor"
      ]

  const allFarmingLocations = [
    "Santa Cruz",
    "La Paz",
    "Cochabamba",
    "Oruro",
    "Chuquisaca",
    "Tarija",
    "Potosí",
    "Beni",
    "Pando",
    "Sacaba",
    "Montero",
    "Riberalta",
    "Warnes",
    "La Guardia",

  ]

  const slide = (shift) => {
    topTractorPlacesScroll.current.scrollLeft += shift;
  };

  return (

    <div
      className={`${location.pathname === "/"
        ? "search-section"
        : "search-section search-extra-style"
        }`}
    >

      <div className="white-container" />

      <div className="top-section">

        <div className="filter-buttons">

          {
            tractorFilterContainer.map((details, index) => {
              return (
                <button onClick={() => { setActiveTractorFilter(index) }} key={index}>
                  {details}
                  <div
                    style={{
                      width: '90%',
                      height: '2px',
                      backgroundColor: 'white',
                      borderRadius: '2px',
                      margin: '6px auto',
                      transition: 'all',
                      transitionDuration: '500ms',
                      opacity: activeTractorFilter === index ? 1 : 0
                    }} />
                </button>
              )
            })
          }

        </div>

      </div>

      <div className="container">
        {/* <div className="option" onClick={() => setMenuShowHide(!menuShowHide)}>
          <p>Categoría</p>
          <KeyboardArrowDownOutlinedIcon />
        </div> */}

        <div className="center">
          <div className="location">
            <MyLocationOutlinedIcon
              style={iconStyle}
              onMouseEnter={() => setLocationShowHide(!locationShowHide)}
              onMouseLeave={() => setLocationShowHide(!locationShowHide)}
              className="myLocationOutlinedIcon"
            />
            <input type="text" name="" id="" value={city} onChange={(e) => { setCity(e.target.value) }} className="location-input" />

            <div
              className={`${locationShowHide
                ? "location-tooltip"
                : "location-tooltip hide-tooltip"
                }`}
            >
              <KeyboardDoubleArrowUpOutlinedIcon />
              <p>
                Buscar : <span> Cerca de mí</span>
              </p>
            </div>
          </div>


          <div className="cal">
            <CalendarMonthOutlinedIcon
              style={iconStyle}
              onClick={() => setCalenderShowHide(!calenderShowHide)}
              onMouseEnter={() => setCalShowHide(true)}
              onMouseLeave={() => setCalShowHide(false)}
            />

            <p className="date-time">
              <span onClick={() => setCalenderShowHide(!calenderShowHide)}>
                {startDate
                  ? format(startDate, "dd MMM yyyy ", { locale: enGB })
                  : "De"}
              </span>
              - {" "}
              <span onClick={() => setCalenderShowHide(!calenderShowHide)}>
                {endDate
                  ? format(endDate, "dd MMM yyyy", { locale: enGB })
                  : "A"}
              </span>
            </p>


            {/* <p className="date-time">
               De
              <span onClick={() => setCalenderShowHide(!calenderShowHide)}>
                {dateOne}
              </span>
              - A
              <span onClick={() => setCalenderShowHide(!calenderShowHide)}>
                {dateTwo}
              </span>
            </p> */}


            <div
              className={`${calShowHide ? "cal-tooltip" : "cal-tooltip hide-tooltip"
                }`}
            >
              <KeyboardDoubleArrowUpOutlinedIcon />
              <p>
                Abierto <span> Calendario</span>
              </p>
            </div>



          </div>
        </div>

        <div className="search-new-section">

          <input
            type="text"
            placeholder={activeLanguage.activeLanguage === "Español" ? "Búsqueda de tractor pequeño, mediano....." : "Search for small, medium tractor....."} />

        </div>

        <div className="search-btn">
          <div className="s-btn">
            <button onClick={() => navigate("/search")}>Buscar</button>
          </div>
        </div>

        {/* <div
          className={`${
            calenderShowHide ? "calender" : "calender hide-calender"
          }`}
        > */}

        <div
          className={`${calenderShowHide ? "calender" : "calender hide-calender"
            }`}
        >
          {/* <Calendar onChange={setDate} value={date} /> */}
          {/* <DatePickerProvider style={{ padding: "10px !important" }}>
            <GetDate />
          </DatePickerProvider> */}



          <div className="newcal">
            <DateRangePickerCalendar
              startDate={startDate}
              endDate={endDate}
              focus={focus}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              onFocusChange={handleFocusChange}
              locale={enGB}
            />
            <div className="calbtn">
              <button onClick={() => setCalenderShowHide(!calenderShowHide)}>SAVE</button>
            </div>
          </div>


        </div>
      </div>

      <div
        className={`${menuShowHide ? "option-menu" : "option-menu option-menu-hide"
          } `}
      >
        <div className="menu-container">
          <div className="menu-one">
            <div className="menu-items">
              <input type="checkbox" value="" />
              <span>Alquiler de tractor</span>
            </div>

            <div className="menu-items">
              <input type="checkbox" />
              <span>Alquiler de Oruga</span>
            </div>

            <div className="menu-items">
              <input type="checkbox" />
              <span>tractor para constricción</span>
            </div>
          </div>

          <div className="menu-two">
            <div className="menu-items">
              <input type="checkbox" />
              <span>Tractor para transporte de materiales</span>
            </div>

            <div className="menu-items">
              <input type="checkbox" />
              <span>Reparación de Tractores</span>
            </div>

            <div className="menu-items">
              <input type="checkbox" />
              <span>Tractor en alquiler mensual</span>
            </div>
          </div>

          <div className="menu-three">
            <div className="menu-items">
              <input type="checkbox" />
              <span>Tractores pesados</span>
            </div>

            <div className="menu-items">
              <input type="checkbox" />
              <span>Servicios de arado</span>
            </div>


            <div className="menu-items">
              <input type="checkbox" />
              <span>Otros</span>
            </div>


          </div>
        </div>
      </div>

      <div className="bottom-section">

        <div className="left">

          <TroubleshootIcon />

          <p>
            {
              activeLanguage.activeLanguage === "Español" ?
                "Áreas agrícolas"
                :
                "Agricultural areas"
            }
          </p>

        </div>

        <div className="Button left-Button" onClick={() => { slide(-150) }}>
          <i className="fa-solid fa-arrow-left"></i>
        </div>

        <div className="Button right-Button" onClick={() => { slide(+150) }}>
          <i className="fa-solid fa-arrow-right"></i>
        </div>

        <div
          className="sliding-container"
          style={{
            scrollbarWidth: 'none'
          }}
          ref={topTractorPlacesScroll}>

          {
            allFarmingLocations.map((details, index) => {
              return (
                <div
                  className="location-name"
                  key={index}
                  style={{
                    marginLeft: index === 0 && '40px',
                    marginRight: index === (allFarmingLocations.length - 1) && '40px'
                  }}>
                  {details}
                </div>
              )
            })
          }

        </div>

      </div>

    </div>
  );
};

export default SearchBar;

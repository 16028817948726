import React from 'react'

const FourthComponent = () => {
    return (
        <div className="FourthComponent">

            <div className="container">

                <p style={{ color: "#AB0F0C" }}>
                    What we do
                </p>

                <h1>
                    We help businesses create engaging and valued interactions
                </h1>

                <p style={{ color: "#747474", fontWeight: "500" }}>
                    Kore.ai has been at the forefront of AI for over a decade, bringing intelligent virtual assistants to life in both customer and employee-facing use cases. Our goal is to simplify AI adoption and get you to meaningful business outcomes faster.
                </p>

                <div className="buttonContainer">

                    <button className="seePricing" name='seePricingButton'>
                        See Pricing
                    </button>

                    <button className="talkToAnExpert">
                        Talk to an expert
                    </button>

                </div>

            </div>

        </div>
    )
}

export default FourthComponent
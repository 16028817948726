import React, { useEffect, useState } from 'react'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useSelector } from 'react-redux';

const PricingComponent = () => {

    const [planPeriod, setPlanPeriod] = useState('Monthly')
    const [activePlanIndex, setActivePlanIndex] = useState(1)

    const pricingBoxArray = [
        {
            planName: "Plan name",
            planTag: "Lorem ipsum dolor sit amet.",
            planPrice: planPeriod === "Monthly" ? "25" : "20",
            featuresArray: [
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
            ]
        },
        {
            planName: "Plan name",
            planTag: "Lorem ipsum dolor sit amet.",
            planPrice: planPeriod === "Monthly" ? "25" : "20",
            featuresArray: [
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
            ]
        },
        {
            planName: "Plan name",
            planTag: "Lorem ipsum dolor sit amet.",
            planPrice: planPeriod === "Monthly" ? "25" : "20",
            featuresArray: [
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
                "Lorem ipsum dolor sit amet consectetur.",
            ]
        },
    ]

    const languageOption = useSelector(state => state.language.language)

    return (
        <div>

            <p className='py-[40px] w-[90%] 1000px:w-[80%] mx-auto text-[20px] text-gray-600'>
                {
                    languageOption === 'English' ?
                    `Welcome to our pricing page, where you'll find the perfect plan to meet your needs. Our pricing options are designed with you in mind, offering flexibility and value for every user. Whether you're an individual, a startup, or a growing business, we have a plan that fits. Choose from our range of subscription tiers, each packed with features that empower you to make the most out of our services. We believe in transparency, so you'll find clear pricing details and no hidden fees. Explore our pricing options and take the next step in achieving your goals with our reliable and cost-effective solutions.`
                    :
                    `Bienvenido a nuestra página de precios, donde encontrará el plan perfecto para satisfacer sus necesidades. Nuestras opciones de precios están diseñadas pensando en usted y ofrecen flexibilidad y valor para cada usuario. Ya sea que sea un individuo, una nueva empresa o una empresa en crecimiento, tenemos un plan que se adapta a sus necesidades. Elija entre nuestra gama de niveles de suscripción, cada uno de ellos repleto de funciones que le permitirán aprovechar al máximo nuestros servicios. Creemos en la transparencia, por lo que encontrará detalles claros sobre los precios y sin cargos ocultos. Explore nuestras opciones de precios y dé el siguiente paso para lograr sus objetivos con nuestras soluciones confiables y rentables.`
                }
            </p>

            <div
                className='w-[200px] mx-auto my-[40px] flex bg-white items-center justify-between py-[10px] rounded-full relative font-bold'>

                <div
                    className={`w-[100px] h-full rounded-full z-[1] absolute left-0 bg-green-600 ${planPeriod === 'Monthly' ? ' translate-x-0 ' : ' translate-x-full '} transition-all duration-500`} />

                <p
                    className={`z-[2] ${planPeriod === 'Monthly' && ' text-white '} cursor-pointer w-1/2 text-center`}
                    onClick={() => { setPlanPeriod('Monthly') }}>
                        {
                            languageOption === 'English' ?
                            'Monthly'
                            :
                            'Mensual'
                        }
                </p>

                <p
                    className={`z-[2] ${planPeriod === 'Yearly' && ' text-white '} cursor-pointer w-1/2 text-center`}
                    onClick={() => { setPlanPeriod('Yearly') }}>
                    {
                            languageOption === 'English' ?
                            'Yearly'
                            :
                            'Anual'
                        }
                </p>

            </div>

            <div className='w-full flex items-center justify-center gap-[40px] mt-[20px] flex-col 1200px:flex-row'>

                {
                    pricingBoxArray.map((pricingBox, index) => {
                        return (
                            <div
                                className={`${index === activePlanIndex ? ' bg-green-600 text-white scale-110 ' : ' bg-white text-black '} p-[20px] rounded-[20px] w-[80%] 500px:w-[360px] shadow-md transition-all duration-500 cursor-pointer`}
                                key={index}
                                onClick={() => { setActivePlanIndex(index) }}>

                                <p className='font-bold text-[20px]'>
                                    {pricingBox.planName}
                                </p>

                                <p className='text-[10px]'>
                                    {pricingBox.planTag}
                                </p>

                                <div
                                    className='mt-[26px]'>

                                    <p className={`font-bold text-[26px] ${index !== activePlanIndex && 'text-green-600'}`}>
                                        ${pricingBox.planPrice}
                                    </p>
                                    <p>
                                        {
                                            languageOption === 'English' ?
                                            'Per user per'
                                            :
                                            'Por usuaria por' 
                                        } {planPeriod}
                                    </p>

                                </div>

                                <div
                                    className='mt-[26px] flex flex-col gap-[8px]'>

                                    {
                                        pricingBox.featuresArray.map((features, serial) => {
                                            return (
                                                <div className='flex items-center gap-[4px]' key={serial}>
                                                    <KeyboardArrowRightIcon className='text-blue-500' />
                                                    <p className='text-[14px]'>
                                                        {features}
                                                    </p>
                                                </div>
                                            )
                                        })
                                    }

                                </div>

                            </div>
                        )
                    })
                }

            </div>

            <div className='w-full pt-[60px] pb-[40px] flex items-center justify-center flex-col gap-[20px]'>

                <p>
                    Start a free trial for 30days. No credit card required.
                </p>

                <button
                    className='w-fit px-[30px] py-[10px] bg-green-600 text-white font-bold rounded-full'>
                    Free trial
                </button>

            </div>

            <p className='py-[40px] w-[90%] 1000px:w-[80%] mx-auto text-[20px] text-gray-600'>
                {
                    languageOption === 'English' ?
                    `At Holatractor, we understand that pricing is a crucial factor in your decision-making process. That's why we've created a straightforward pricing structure that ensures you get the best value for your investment. With our competitive rates and a commitment to providing exceptional service, you can trust that you're making a smart choice. Whether you're looking for essential features, advanced capabilities, or enterprise-level solutions, our pricing page offers the information you need to make an informed decision. Join our community of satisfied customers who have found success and peace of mind in choosing the right pricing plan for their unique requirements. We look forward to serving you and helping you thrive in your endeavors.`
                    :
                    `En Holatractor, entendemos que el precio es un factor crucial en su proceso de toma de decisiones. Es por eso que hemos creado una estructura de precios sencilla que garantiza que usted obtenga el mejor valor por su inversión. Con nuestras tarifas competitivas y el compromiso de brindar un servicio excepcional, puede confiar en que está tomando una decisión inteligente. Ya sea que esté buscando funciones esenciales, capacidades avanzadas o soluciones de nivel empresarial, nuestra página de precios ofrece la información que necesita para tomar una decisión informada. Únase a nuestra comunidad de clientes satisfechos que han encontrado el éxito y la tranquilidad al elegir el plan de precios adecuado para sus necesidades específicas. Esperamos poder servirle y ayudarle a prosperar en sus esfuerzos.`
                }
            </p>

        </div>
    )
}

export default PricingComponent
import React, { useState } from 'react'
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';

const PopUp = ({ active, imageLink, heading, videoLink }) => {

    const [open, setOpen] = useState(false)

    return (
        <div
            className={`${active ? 'activeProfile' : 'inactiveProfile'}`}
            onClick={() => { setOpen(true) }}
            style={{ position: 'relative' }}>

            <img
                src={imageLink}
                alt="" />

            <p>
                {heading}
            </p>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >

                <CloseIcon
                    style={{
                        position: 'absolute',
                        right: '10px',
                        top: '10px',
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        setOpen(false)
                    }} />

                <video
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    playsInline={true}
                    className='absolute w-full h-full object-cover top-0 left-0 z-[-1]'
                    style={{
                        maxWidth: '1000px',
                        width: '90vw',
                        maxHeight: '80vh',
                        height: 'auto',
                        objectFit: 'cover',
                        borderRadius: '8px'
                    }}>
                    <source
                        src={videoLink}
                        type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

            </Backdrop>

        </div>
    )
}

export default PopUp
import React from 'react'
import EastIcon from '@mui/icons-material/East';
import Spider from '../../assets/booking/spider.png'
import { useSelector } from 'react-redux';

const Hero = () => {
    const { activeLanguage } = useSelector(state => state.Languages)
    return (
        <div className='Hero'>
            {
                activeLanguage === "Español" ?
                <div className="left">
                <div className="headingContainer">
                    <h1>
                        <span className='dive'>Reserva</span> de tractores,
                    </h1>
                    <h1>
                    agricultura <span className="vr">tecnológica</span>
                    </h1>
                </div>
                <p>
                Agilice sus operaciones agrícolas con Holatractor. Reserva sencilla, servicio confiable y soluciones sostenibles. .experimentar una mayor eficiencia, menores costos y una mayor resiliencia climática.
                </p>
                <div className="buttonSection">
                    <button
                        name='build your world button'>
                        Reservar un tractor
                    </button>
                    <div className="arrowIcon">
                    <EastIcon />
                    </div>
                </div>
            </div>
                :
            <div className="left">
                <div className="headingContainer">
                    <h1>
                        <span className='dive'>Booking</span> of tractors,
                    </h1>
                    <h1>
                    agriculture <span className="vr">technological</span>
                    </h1>
                </div>
                <p>
                Streamline your farming operations with Holatractor. Easy booking, reliable service and sustainable solutions. .experience greater efficiency, lower costs and greater climate resilience.
                </p>
                <div className="buttonSection">
                    <button
                        name='build your world button'>
                        Reserve a tractor
                    </button>
                    <div className="arrowIcon">
                    <EastIcon />
                    </div>
                </div>
            </div>
            }

            <div className="right">

                <div className="image_container">
                    <div className="image_box">
                        <img
                            alt="booking banner image"
                            className="banner"
                            src="https://holaimagesdata.s3.us-west-2.amazonaws.com/web/booking/smart_farming_tractor.webp" />
                    </div>
                </div>

                <img
                    alt="spider"
                    className="spider"
                    src={Spider} />

            </div>
        </div>
    )
}

export default Hero
import React from 'react'
import './LoanApplication.scss'

const LoanApplication = () => {
    return (
        <div className='LoanApplication'>

            <h1>
                Application Form
            </h1>

            <div className="addressSection">

                <input type="text" className='PAN' placeholder='Pancard *' />
                <input type="text" className='Loan_amount' placeholder='Loan Amount' />
                <input type="text" className='Monthly income' placeholder='Monthly income' />

                <select name="purpose_selection" id="purpose_selection">

                    <option value="0">--Select--</option>
                    <option value="lorem">lorem</option>
                    <option value="lorem">lorem</option>
                    <option value="lorem">lorem</option>
                    <option value="lorem">lorem</option>

                </select>

            </div>

            <h3 style={{marginBottom: '-20px'}}>
                Person details
            </h3>

            <div className="addressSection">

                <input type="text" className='full_name' placeholder='Full name' />
                <input type="email" className='email' placeholder='Email' />
                <input type="text" className='Mobile number' placeholder='Mobile number' />
                <input type="test" className='dob' placeholder='Birth date' />
                <input type="text" className='adhaar' placeholder='Adhaar number' />

                <div/>

                <select name="purpose_selection" id="purpose_selection">

                    <option value="0">--Select Gender--</option>
                    <option value="lorem">lorem</option>
                    <option value="lorem">lorem</option>
                    <option value="lorem">lorem</option>

                </select>

                <select name="purpose_selection" id="purpose_selection">

                    <option value="0">--Select State--</option>
                    <option value="lorem">lorem</option>
                    <option value="lorem">lorem</option>
                    <option value="lorem">lorem</option>

                </select>

                <select name="purpose_selection" id="purpose_selection">

                    <option value="0">--Select City--</option>
                    <option value="lorem">lorem</option>
                    <option value="lorem">lorem</option>
                    <option value="lorem">lorem</option>

                </select>

                <input type="text" className='pin address' placeholder='Pincode' />

            </div>

            <p>
            “I hereby give my free consent and authorize UCA Finvest Private Limited and its service provider by providing my personal information to obtain my Credit Information Report and Credit Score from CIBIL and Equifax Credit Information Services Pvt. Ltd. for the purpose of risk assessment and credit underwriting and not for any other purposes". Click here to indicate that you have read and agree to the terms presented in the ** <span style={{color: "#AB0F0C"}}>"Terms and Conditions"</span> and <span style={{color: "#AB0F0C"}}>"Privacy Policy"</span> 
            </p>

            <button
        name='submit_for_a_loan_button'>
            Submit
        </button>

        </div>
    )
}

export default LoanApplication
import React from 'react'

const PalmRecollectionSeven = () => {

    const contentArray = [
        {
            imageLink: "https://wallpapercave.com/wp/wp11484884.jpg",
            imageHeading: "Field Inspection & Loss Recovery Control",
            imagePara: "Recovering and Reducing Field Losses as well as monitor and maintain a clean and well cared for field for health palm growth."
        },
        {
            imageLink: "https://wallpapercave.com/wp/wp11484884.jpg",
            imageHeading: "Field Inspection & Loss Recovery Control",
            imagePara: "Recovering and Reducing Field Losses as well as monitor and maintain a clean and well cared for field for health palm growth."
        },
        {
            imageLink: "https://wallpapercave.com/wp/wp11484884.jpg",
            imageHeading: "Field Inspection & Loss Recovery Control",
            imagePara: "Recovering and Reducing Field Losses as well as monitor and maintain a clean and well cared for field for health palm growth."
        },
        {
            imageLink: "https://wallpapercave.com/wp/wp11484884.jpg",
            imageHeading: "Field Inspection & Loss Recovery Control",
            imagePara: "Recovering and Reducing Field Losses as well as monitor and maintain a clean and well cared for field for health palm growth."
        },
        {
            imageLink: "https://wallpapercave.com/wp/wp11484884.jpg",
            imageHeading: "Field Inspection & Loss Recovery Control",
            imagePara: "Recovering and Reducing Field Losses as well as monitor and maintain a clean and well cared for field for health palm growth."
        },
        {
            imageLink: "https://wallpapercave.com/wp/wp11484884.jpg",
            imageHeading: "Field Inspection & Loss Recovery Control",
            imagePara: "Recovering and Reducing Field Losses as well as monitor and maintain a clean and well cared for field for health palm growth."
        },
    ]

  return (
    <div
    className='w-[90%] 1000px:w-[80%] mx-auto py-[40px]'>

        <div className='w-full grid grid-cols-1 900px:grid-cols-2 gap-8'>

            {
                contentArray.map((contents, index)=>{
                    return (
                        <div
                        key={index}
                        className='flex flex-col items-center justify-center w-full gap-[10px]'>

                            <img 
                            src={contents.imageLink} 
                            alt=""
                            className='w-full object-cover rounded-[20px]' />

                            <div className='flex gap-[4px] text-[20px] 900px:text-[26px] font-bold text-[#23A455]'>
                                <span>
                                    {index+1}-
                                </span>
                                <span>
                                    {contents.imageHeading}
                                </span>
                            </div>

                            <p className='text-[18px] text-gray-600 text-center'>
                                {contents.imagePara}
                            </p>

                        </div>
                    )
                })
            }

        </div>

    </div>
  )
}

export default PalmRecollectionSeven
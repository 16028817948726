import React from "react";
import "./footer.scss";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PStore from "./../../assets/play-store-logo-33874.png";
import AStore from "./../../assets/pngwing.com.png";
import Logo from "./../../utils/nnacres_white_v2.png";
import FLogo from "./../../assets/housing.820bbe77.webp";
import FLogoTwo from "./../../assets/gptweconomic.c3804eff.webp";
import { Link } from "react-router-dom";

import ScannerImg from "./../../assets/qr_hola.png";
import { useSelector } from "react-redux";

const Footer = () => {

  const { activeLanguage } = useSelector(state => state.Languages)

  return (
    <div className="footer-section">
      <div className="container">
        <div className="footer-one">
          <div className="footer-logo">
            <img src={Logo} alt="logo" />
          </div>
          <div className="logo-two">
            {/* <img src={FLogoTwo} alt="logo" /> */}
            {/* <img src={FLogoThree} alt="logo" /> */}
          </div>
          <div className="socials">
            {
              activeLanguage === "Español" ?
                <p>SEGUIR</p>
                :
                <p>CONTINUE</p>
            }
            <div className="social-icons">
              <FacebookOutlinedIcon />
              <InstagramIcon />
              <TwitterIcon />
              <LinkedInIcon />
              <YouTubeIcon />
            </div>
          </div>
          {
            activeLanguage === "Español" ?
              <div className="copyright">
                <p>Ponte en contacto con nuestro equipo en: hola@holatractor.com</p>
                <p>Oficina Bolivia: Parque Industrial Oficina 32</p>
                <p>Teléfono: +59177613811</p>
                <p>2019 - 2024 Holatractor </p>
              </div>
              :
              <div className="copyright">
                <p>Get in touch with our team at: hola@holatractor.com</p>
                <p>Bolivia´s office: Parque Industrial Office 32</p>
                <p>Phone: +59177613811 </p>
                <p>2019 - 2024 Holatractor </p>
              </div>
          }
        </div>
        {
          activeLanguage === "Español" ?
            <div className="footer-two">
              <h4>COMPAÑÍA</h4>
              <ul>
                <li>
                  <a href="http://">Carreras </a>
                </li>
                <li>
                  <a href="http://">Sobre nosotros </a>
                </li>
                <li>
                  <a href="http://">Para propietarios de tractores</a>
                </li>
                <li>
                  <Link to={"/terms-and-condition"}>Términos</Link>
                </li>
                <li>
                  <a href="http://">Anual Return </a>
                </li>
                <li>
                  {/* <a href="http://">Política de privacidad </a> */}
                  <Link to={"/privacy-policy"}>Política de privacidad </Link>
                </li>
                <li>
                  <a href="http://"> Contacta con nosotros</a>
                </li>
                <li>
                  <a href="http://">UnSubscribe </a>
                </li>
                <li>
                  <a href="http://">Anuncio</a>
                </li>
              </ul>
            </div>
            :
            <div className="footer-two">
              <h4>COMPANY</h4>
              <ul>
                <li>
                  <a href="http://">Careers </a>
                </li>
                <li>
                  <a href="http://">About us </a>
                </li>
                <li>
                  <a href="http://">For tractor owners</a>
                </li>
                <li>
                  <Link to={"/terms-and-condition"}>Terms</Link>
                </li>
                <li>
                  <a href="http://">Annual Return </a>
                </li>
                <li>
                  {/* <a href="http://">Política de privacidad </a> */}
                  <Link to={"/privacy-policy"}>Privacy Policy </Link>
                </li>
                <li>
                  <a href="http://"> Contact us</a>
                </li>
                <li>
                  <a href="http://">UnSubscribe </a>
                </li>
                <li>
                  <a href="http://">Advertisement</a>
                </li>
              </ul>
            </div>
        }
        {
          activeLanguage === "Español" ?
            <div className="footer-three">
              <h4>SOCIOS</h4>
              <ul>
                <li>
                  <a href="https://www.ifad.org/en/">IFAD</a>
                </li>
                <li>
                  <a href="https://innovatech-latam.com/en/">Innovatech</a>
                </li>
                <li>
                  <a href="http://">Communinad Virtual</a>
                </li>
                <li>
                  <a href="https://iica.int/en">IICA</a>
                </li>
                <li>
                  <a href="http://">BID</a>
                </li>
                <li>
                  <a href="https://www.sparkasse-aachen.de/en/home.html">Sparkasse Aachen</a>
                </li>
              </ul>
            </div>
            :
            <div className="footer-three">
              <h4>PARTNERS</h4>
              <ul>
                <li>
                  <a href="https://www.ifad.org/en/">IFAD</a>
                </li>
                <li>
                  <a href="https://innovatech-latam.com/en/">Innovatech</a>
                </li>
                <li>
                  <a href="http://">Communinad Virtual</a>
                </li>
                <li>
                  <a href="https://iica.int/en">IICA</a>
                </li>
                <li>
                  <a href="http://">BID</a>
                </li>
                <li>
                  <a href="https://www.sparkasse-aachen.de/en/home.html">Sparkasse Aachen</a>
                </li>
              </ul>
            </div>
        }
        {
          activeLanguage === "Español" ?
            <div className="footer-four">
              <h4>SOCIOS</h4>
              <ul>
                <li>
                  <a href="http://">Noticias</a>
                </li>
                <li>
                  <a href="http://">Inversores</a>
                </li>
                <li>
                  <a href="http://">mapa del sitio</a>
                </li>
                <li>
                  <a href="http://">Internacional</a>
                </li>
              </ul>
            </div>
            :
            <div className="footer-four">
              <h4>PARTNERS</h4>
              <ul>
                <li>
                  <a href="http://">News</a>
                </li>
                <li>
                  <a href="http://">Investors</a>
                </li>
                <li>
                  <a href="http://">Site Map</a>
                </li>
                <li>
                  <a href="http://">International</a>
                </li>
              </ul>
            </div>
        }
        {
          activeLanguage === "Español" ?
          <div className="footer-five">
          <h4>EXPERIMENTA LA APLICACIÓN HOLATRACTOR EN MÓVIL</h4>
          <a className="pstore-logo" href="https://play.google.com/store/apps/details?id=com.nims.holatractor">
            <img src={PStore} alt="PlayStore Logo" />
            {/* <img src={AStore} alt="AppleStore Logo" /> */}
          </a>
          <div className="scanner">
            <img src={ScannerImg} alt="Scanner" />
            <p>Abra la cámara y escanee el código QR para descargar la aplicación</p>
          </div>
        </div>
            :
            <div className="footer-five">
          <h4>EXPERIENCE THE HOLATRACTOR APP ON MOBILE</h4>
          <a className="pstore-logo" href="https://play.google.com/store/apps/details?id=com.nims.holatractor">
            <img src={PStore} alt="PlayStore Logo" />
            {/* <img src={AStore} alt="AppleStore Logo" /> */}
          </a>
          <div className="scanner">
            <img src={ScannerImg} alt="Scanner" />
            <p>Open the camera and scan the QR code to download the app</p>
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default Footer;
